body {
	margin: 0px;
	padding: 0px;
	padding-right: 0px!important;
	font-family: 'Nunito', sans-serif;
	font-style: normal;
	position: relative;
	overflow-x: hidden;
	background-color: #FFFFFF;
}
button, [type=button], [type=reset], [type=submit]{
	-webkit-appearance: initial
}
button{
	font-family: 'Nunito', sans-serif;
	font-style: normal;
}
.centered{							
	text-align: center;	
}		
.frontpage-bg{
	background-image: url("/images/frontpage-bg.jpg"); 
	background-attachment: local;
	background-position: right top;
	background-size: 125% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden;
	padding-bottom:90px;
	transition: background-size 0.8s ease-in;
	-moz-transition: background-size 0.8s ease-in;
	-ms-transition: background-size 0.8s ease-in;
	-o-transition: background-size 0.8s ease-in;
	-webkit-transition: background-size 0.8s ease-in;
	transition-delay: 1.4s;
	-moz-transition-delay: 1.4s;
	-ms-transition-delay: 1.4s;
	-o-transition-delay: 1.4s;
	-webkit-transition-delay: 1.4s;
	&.doc-ready{
		background-size: 58% 100%;
	}
	@include breakpoint(md){
		background-image: none;
		padding-bottom:10px;
	}
	.bg-line-1{
		position: absolute;
	    top: 0px;
	    left: 46%;
	    height: 100%;
	    overflow: hidden;
	    transition: all 0.3s ease-in-out;
	    svg{
	    	transition: all 0.3s ease-in-out;
	    	@include breakpoint(md){
				display: none;
			}
	    }
	}
	.bg-line-2{
		position: absolute;
	    top: 0px;
	    left: 53%;
	    height: 100%;
	    overflow: hidden;
	    transition: all 0.3s ease-in-out;
	    svg{
	    	transition: all 0.3s ease-in-out;
	    	@include breakpoint(md){
				display: none;
			}
	    }
	}
	.bg-line-3{
		position: absolute;
	    top: 0px;
	    left: 57%;
	    height: 100%;
	    overflow: hidden;
	    transition: all 0.3s ease-in-out;
	    svg{
	    	transition: all 0.3s ease-in-out;
	    	@include breakpoint(md){
				display: none;
			}
	    }
	}
	.ws-bunner-container {            /*ws_bunner style	*/
		position: relative;
		z-index: 10;
		padding-top: 50px;
		padding-bottom: 70px;	
		@include breakpoint(md){
			padding-bottom: 60px;
		}
		.ws-bunner-test{			
			max-width: 390px;
			margin-right: auto;
			@include breakpoint(md){
				max-width: 100%;
				padding-bottom: 80px;
			}
			.ws-text-heading{
				padding-top: 20px;				
				padding-bottom: 25px;
				border-bottom: 1px solid #E5EAF5;			
				h1{
					font-weight: 600;
					font-size: 40px;
					color: #000326;
					@include breakpoint(md){
						font-size: 34px;
					}
					@include breakpoint(sm-md){
						text-align: center;
					}	   
				}

			}
			.ws-text-content{
				padding-top: 30px;
				padding-bottom: 55px;
				@include breakpoint(sm-md){
					text-align: center;
				}
				p{
				   font-weight: 600;
				   font-size: 16px;
				   color: #8492A5;
				   strong{
				   	   font-weight: 600;
					   font-size: 16px;
					   color: #8492A5;
				   }
				   abbr{
				   	   font-weight: 600;
					   font-size: 16px;
					   color: #8492A5;
					   text-decoration: none;
				   }
				}
			}
			.bunner-test{
				max-width: 390px;
				width: 100%;
				padding: 10px 20px;
				border: none;
				background: #3A76CE;
				box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
				border-radius: 4px;
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #FFFFFF;
				transition: all 0.3s ease-in-out;
				outline: none;
				text-decoration: none;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				margin: 0 auto;
				&:hover{
					background-color: #184282;
				}

			}
		}
		.ws-bunner-image{
			display: flex;
		    justify-content: center;
		    align-items: center;
		    align-content: center;
		    width: 100%;
		    height: 100%;
		    position: relative;
		    @include breakpoint(md){
		    	background: #F0F3F9;
		    	margin-left: -15px;
		    	width: calc(100% + 30px);
		    	padding: 40px 0px;
		    }
		    @include breakpoint(xs){
		    	background: #F0F3F9;
		    	margin-left: -15px;
		    	width: calc(100% + 30px);
		    	padding: 40px 0px;
		    }
		    img{
				max-width: 100%;
				height: auto;
			}
		}
	}
	.frontpage-test-access{  /*	frontpage-test-access style*/
		.test-access-heading{
			width: 100%;
			padding-bottom: 50px;
			border-bottom: 1px solid #E0E6ED;
			@include breakpoint(xs){
				padding-bottom: 40px;
			}
			h2,				
			h4{
				font-weight: bold;
				font-size: 24px;
				color: #000326;
				margin: 0px;
				strong{
					font-weight: bold;
					color: #000326;
				}
				@include breakpoint(md){
					font-size: 20px;
				}
				@include breakpoint(xs){
					font-size: 18px;
					margin-bottom: 15px;
				}
			}
		}
		.test-access-content{
			padding-top: 40px;			
			display: flex;
			justify-content: space-between;
			@include breakpoint(md){
				flex-direction: column;
			}
			@include breakpoint(xs){
		    	margin-left: -15px;
		    	width: calc(100% + 30px);
		    }
			.test-quick-access{
				max-width: 800px;
				width: 100%;
				margin-right: auto;	
				@include breakpoint(md){
					max-width: 100%;
					padding: 0px;
					width: 100%;
					padding-left: 15px;
					padding-right: 15px;
				}	
				@include breakpoint(xs){
					order: 1;
					padding: 0px;
				}	
				.test-access-holder{	
					position: relative;				
					display: block;
					margin-bottom: 80px;
					@include breakpoint(xs){
						margin-bottom: 0px;
					}
	   				.test-access-bg{
	   					border-radius: 4px;
	   					width: 100%;
						height: 320px;
						background-color: #3A76CE;
						@include breakpoint(sm-md){
							display: block;
							height: 380px;
						}
						@include breakpoint(xs){

						}
	   				}
	   				.test-image-part{
	   					display: block;
						position: absolute;
						background-image: url('/images/Mask-Group.svg');
						background-size: cover;
						background-repeat: no-repeat;
						top: 0px;
						left: 0px;
						z-index: 2;
						width: 100%;
						height: 380px;
						@include breakpoint(sm-md){
							display: block;
							height: 435px;
						}
						@include breakpoint(xs){
							display: none;
						}											
					}
	   				.test-access-part{
	   					position: absolute;
	   					top: 30px;
					 	bottom: 30px;
	   					right: 30px;
						left: 30px;
	   					z-index: 10;
						max-width: 305px;
						margin-left: auto;
						@include breakpoint(xs){
						    max-width: 100%;
						    right: 15px;
						    left: 15px;
						}
						.test-title-available{
							span,
							mark{
								background: transparent;
								font-weight: bold;
								font-size: 20px;
								color: #FFFFFF;
								text-transform: capitalize;
							}
						}
						.test-time{
							margin-top: 15px;
							margin-bottom: 35px;
							display: flex;
							justify-content: flex-start;
							align-items: baseline;
							.num-qst{
								margin-right: 16px;
								color: #FFFFFF;
								span.fill-white{
									svg{
										path{
											fill: #ffffff;
										}
									}
								}
								.num-qst-txt{
									font-weight: 600;
									font-size: 12px;
									color: #FFFFFF;	
									margin-left: 5px;
								}
								.review-stars{
									font-size:9px;
								}
							}
						}						
						.take-test-btn{						
							background: #FFFFFF;
							border-radius: 4px;
							padding: 13px 36px;
							border: 1px solid transparent;
							font-weight: 600;
							font-size: 16px;
							color: #3A76CE;
							outline: none;								
							max-width: 362px;
							width: 100%;	
							display: block;
							text-decoration: none;
							transition: all 0.3s ease-in-out;
							&:hover,
							&:focus{
								color:#fff;
								background-color: transparent;
								border-color: #FFFFFF;
							}

						}
					}		
				}	
			}
			.text-explaining{
				margin: 15px 0px;
				display: flex;				
				width: 100%;
				@include breakpoint(lg){
					flex-wrap: wrap;
				}
				@include breakpoint(xs){
					padding: 0 15px;

				}
				.text-explaining-style{
	    			text-align: left;
	    			position: relative;	    			
	    			align-content: center;
	    			display: flex;
	    			margin: 10px 25px 10px 0;
	    			flex-direction: column;
	    			height: 100%;	    			
	    			@include breakpoint(lg){
						width: 100%;						
						max-width: 100%;
						margin: 0px auto;
					}
	    			p{
	    				margin-bottom: 10px;
	    				font-weight: 600;
						font-size: 16px;
						color: #8492A5;
	    			}
				}
				
			}			
			.scroll-tests-content{
				max-width: 340px;
				width: 100%;				
				@include breakpoint(min-md){
					margin-left: 50px;
				}
				@include breakpoint(md){
					margin-left: auto;
					max-width: 100%;
					width: 100%;
					padding-top: 30px;
					background-color: #F0F3F9;
				}
				.scroll-tests{
					.scroll-rotate{	
						display: none;
						position: relative;
						.scroller{
							position: absolute;
							top: 60px;
							left: -80px;
							-moz-transform:rotate(-90deg);  
							-webkit-transform:rotate(-90deg);  
							-o-transform:rotate(-90deg);  
							-ms-transform:rotate(-90deg);
							@include breakpoint(md){
								left: -45px;
							}
							@include breakpoint(xs){
								left: -55px;
							}
							.scroll-txt{
								font-weight: 600;
								font-size: 12px;
								text-align: center;
								color: #000326;
								margin-right: 15px;
								display: inline-block;
							}
							.scroll-line{
								width: 80px;
							    height: 1px;
							    background-color: #8492A5;
							    display: inline-block;
							    vertical-align: middle;
							}
						}
					}
					.scroll-test-list{
					    max-width: 320px;
					    margin-left: auto;
					    max-height: 450px;
    					overflow: auto;    
    					-ms-overflow-style: none; 
    					scrollbar-width: none; 				
    					&::-webkit-scrollbar {
						  width: 1px;				  
						}
						&::-webkit-scrollbar-track {
						  background: transparent;
						}
						&::-webkit-scrollbar-thumb {
						  background: transparent;
						}
						@include breakpoint(md){
							max-height: 260px;							
							margin-right: auto;
							margin-left: 60px;
							max-width: 100%;
						}
						@include breakpoint(xs){
							order: 1;
							max-height: 185px;
							margin: 0 auto 15px;
							padding: 0 15px;
						}
					    .test-type{   
					    	display: inline-block;
						    align-items: flex-end;
						    margin-bottom: 30px;
						    @include breakpoint(md){
						    	display: block;
						    }
						    p.test-name{
						    	margin: 0px;
						    	font-weight: 600;
							    font-size: 14px;
							    color: #000326;
							    text-align: left;
							    a{
							    	font-weight: 600;
								    font-size: 14px;
								    color: #000326;
								    text-align: left;
								    text-decoration: none;
								    transition: all 0.3s ease-in-out;
								    &:hover,
								    &:focus,
								    &.active{
								    	color: #3A76CE;
								    }
								    span{
								    	vertical-align: text-bottom;
								    }
							    }
						    }
						}
					}
				}
			}
		}
	}
	.test-qualification-review-part{   
		padding-top: 120px;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
  		@include breakpoint(md){
  			flex-direction: column;
  			padding-top: 80px;
  		}
		.test-qualification{
			max-width: 512px;
			margin-right: auto;
			width: 100%;
			margin-right: 50px;
			@include breakpoint(md){
				max-width: 100%;
				margin-right: 0px;
			}
			.test-qualification-heading{				
				margin-bottom: 35px;
				h3{
					font-weight: 600;
					font-size: 44px;
					color: #000326;
					padding-right: 10px;
					@include breakpoint(md){
						font-size: 36px;
						padding-right: 0px;
					}
					strong{
						font-weight: 600;
					}
				}
			}
			.test-qualification-content{
				padding-bottom: 65px;
				border-bottom: 1px solid #E5EAF5;
				margin-bottom: 40px;
				p{
					font-weight: 600;
					font-size: 16px;
					color: #8492A5;
					margin-top: 20px;
					margin-bottom: 0px;
					strong{
						font-weight: 600;
					}
					abbr{
						font-weight: 600;
					}
				}
			}
			.contact-email{
				margin:35px 0px;	
				padding-right: 50px;			
				span.ws-email-text{
					font-weight: 600;
					font-size: 16px;
					color: #8492A5;
					display: block;
				}
				a.ws-email{
					text-decoration: none;
					outline: none;
					color: #3A76CE;
				}	
				@include breakpoint(md){
					padding-right: 0px;
				}			
			}			
		}
		.review-part{
			max-width: 463px;
			width: 100%;
			margin-left: auto;
 			@include breakpoint(md){
 				margin: auto;
 			}
			.clients-review-actions{
				.write-review{
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					padding-bottom: 20px;
					margin-left: 40px;
					@include breakpoint(md){
						margin-left: 0px;
					}
					@include breakpoint(sm){
					    flex-direction: column;
					    justify-content: flex-start;
					    align-items: flex-start;
					    margin-left: 0px;
					}
					.review-title{
						font-weight: 600;
						font-size: 22px;
						color:  #8492A5;
						 @include breakpoint(md){
						 	font-size: 20px;
						 }
					}
					.review-button{
						border: none;
						background-color: transparent;						
						outline: none;
						box-shadow: none;
						padding: 0px;
						.btn-text{
							color: #3A76CE;
							font-weight: bold;
							font-size: 14px;
							margin-left: 5px;
						}
					}					
				}
				.read-review{
					max-height: 540px;
					direction:rtl; 
	    			overflow:auto;
	    			-ms-overflow-style: none; 
    				scrollbar-width: none;  
	    			 &::-webkit-scrollbar {
					    border-radius: 0px;
					    height: 192px;
					    width: 2px;
					  }

					  &::-webkit-scrollbar-thumb {
					    background: #8492A5;
					    width: 1px;
					    height: 192px;
					  }

					  &::-webkit-scrollbar-track {
					    border-radius: 0px;
					  }
					@include breakpoint(sm-md){
						max-height: 465px;
					}
					@include breakpoint(md){
						height: 100%;
						overflow: hidden;
					}
					.posted-reviews{
						direction: ltr;
						@include breakpoint(md){
							display: flex;
							flex-direction: row;
							width: 100%;
						}
						.review-frame{
							background: #3A76CE;
							border-radius: 4px;
							padding: 32px 28px;
							margin-bottom: 28px;
							max-width: 422px;
   							margin-left: 39px;
							@include breakpoint(md){
								padding: 24px 15px;
								margin-left: 0px;
								min-width: 100%;
							}
							.review-content{
								border-left: 1px solid #6E99DA;
								padding-left: 20px;
								.rating-stars{
									padding-bottom: 15px;
									span.star, span.fas, span.far{
										color: #FFFFFF;
									}
								}
								.review-writen-message{
									padding-bottom: 15px;
									font-size: 14px;
									color: #FFFFFF;
									blockquote{
										font-size: 14px;
										color: #FFFFFF;
									}
								}
								.user-info{
									display: flex;
									justify-content: flex-end;
									align-items: center;
									.user-img{
										width: 28px;
										height: 28px;
										border-radius: 50%;
										overflow: hidden;
										position: relative;
										.must-be-rounded{
											display: inline;									
											margin: 0 auto;										
											height: 100%;
											min-width: 100%;
										}									
									}
									.user-name{
										margin-left: 10px;
										font-weight: 600;
										font-size: 14px;
										color: #FFFFFF;
									}
								}
							}
						}					
					}										
				}
				.review-slider-mobile{
					display: none;
					max-width: 422px;
	   				margin: auto;
					@include breakpoint(md){
						display: block;
					}
					.review-previus-arrow{
						width: 41px;
					    height: 41px;
					    border: 1px solid #C9D3DE;
					    text-align: center;
					    border-radius: 50%;
					    cursor: pointer;
					    span{
	    					vertical-align: middle;
					    }
					}
				}
			}
			.contact-email{
				margin:40px 0px;
				margin-left: 40px;
				@include breakpoint(md){
					margin-left: 0px;
				}
				@include breakpoint(sm){
				    margin-left: 0px;
				}			
				span.ws-email-text{
					font-weight: 600;
					font-size: 16px;
					color: #8492A5;
					display: block;
				}
				a.ws-email{
					text-decoration: none;
					outline: none;
					color: #3A76CE;
				}	
				@include breakpoint(md){
					padding-right: 0px;
				}			
			}	
		}
	}
}
