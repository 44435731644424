.test-container{
	position: relative;
	background-color: #FFFFFF;
	border: 1px solid #C9D3DE;
	box-sizing: border-box;
	-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-moz-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	border-radius: 4px;
	padding: 20px 30px 20px 20px;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include breakpoint(md){
		flex-direction: column;
		padding-left: 16px;
		padding-right: 16px;
		align-items: flex-start;	
	}
	span.locked-test-icon{
	  	display: none;
	}
	.test-title{ 	
		text-decoration: none;
		@include breakpoint(md){
			padding-bottom: 10px;
		}
		strong,
		span{
			font-weight: bold;
			font-size: 14px;
			color:  #3A76CE;
			text-decoration: none;
		}
	}
	.test-lasting{
	   	@include breakpoint(md){
	   		display: flex;
	   		width: 100%;
	   	}
	   	.num-of-question, .test-max-time {
	   		display: flex;
	   		justify-content: flex-start;
	   		align-items: baseline;
	   		@include breakpoint (md){
	   			margin-right: 20px;
	   		}
	   		.q-number-text{
	   			display: flex;
	   			font-weight: 600;
				font-size: 12px;
				color: #8492A5;
				.total-test-questions, .total-a-num{
				margin-right: 3px;
				}					
	   		}
	   		span.test-last-icons{
				margin-right: 5px;
			}
	   	}
	}	
}
.locked-test{
	background-color:  #B0BBCB;
	span.locked-test-icon{
		display: block;
		position: absolute;
		top: 8px;
		right: 8px;
	}
	.test-title{
		padding-bottom: 10px;
		span{
			color: #FFFFFF;
		}
	}
	.test-last-icons{
		svg{
			path{
				fill: #FFFFFF;
			}
		}
	}
	.q-number-text{
		color: #FFFFFF!important;
	}
}
.results-content{
  	max-width: 1360px;
  	margin: 0 auto;
  	padding-top: 55px;
  	@include breakpoint(md){padding-top: 40px;}
  	.num-founded-result{
    	font-weight: bold;
		font-size: 20px;
		text-align: left;
		color:  #000326;
		margin-bottom: 20px;
    }
}


.try-tests-holder{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	position: relative;
	justify-content: space-between;
	.test-container{
		width: 48%;
		@include breakpoint(md){
			width: 100%;
		}
	}
}
