.hidden-element{
	display: none !important;
}
.invalid-feedback{
	display: block;
	font-size: 10px;
}
.login-modal-form{
	max-width: 316px;
    width: 100%;
    margin: auto;
	.login-modal-input{
		padding-bottom: 24px;	
		.login-form-control{	
			width: 100%;		
			background: #FFFFFF;
			border: 1px solid #C9D3DE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			padding: 11px 16px 9px 16px; 
			font-weight: 600;
			font-size: 18px;
			color:  #000326;
			@include placeholder-content{
				color: #B0BBCB;
			}
		}
	}
	.password-field{
 		padding-bottom: 17px;
 	}	
 	.forgot-pass{
 		text-align: center;
 		margin-bottom: 32px;
 		a{
 			font-weight: 600;
			font-size: 14px;
			text-align: center;
			color: #8492A5;
			text-decoration: none;			
 		}
 	}	
 	.login-modal-button{
 		padding-bottom: 15px;
 		display: flex;
 		&:last-child{
 			padding-bottom: 0px;
 		}
 		.login-form-singup{
 			background: #FFFFFF;
 			color: #8492A5;
 			font-size: 14px;
 			box-shadow: none;
 			outline: none;
 		}
 		a{
 			font-weight: 600;
			font-size: 14px;
			text-align: center;
			color: #8492A5;
			text-decoration: none;			
 		}
 	}		
}
.login-error-msg{
	width: 100%;
	text-align: center;																				
	padding: 0px 0px;
	background-color: #FFE3E3;
	border: 1px solid #EC6161;
	box-sizing: border-box;
	border-radius: 4px;	
	margin-bottom: 24px;
	opacity: 0;
	visibility: hidden;
	height: 0px;
	&.showed{
		height: auto;
		opacity: 1;
		visibility: visible;
		padding: 8px 0px;
	}
	span{
		font-weight: 600;
		font-size: 12px;
		color: #EC6161;
	}
}
input.login-error{
	border: 1px solid #EC6161!important;
}
.change-pass{
	margin-top: 27px;
}
.pass-changed-login{
	max-width: 152px;
	width: 100%;
	border: 1px solid #3A76CE;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: transparent;
    padding: 11px 20px 9px 20px;
    color: #3A76CE;
    font-weight: 600;
	font-size: 18px;
	text-align: center;
}



.mdl-grid{
	.card{
		position: relative;
	    display: flex;
	    flex-direction: column;
	    width: 100%;  
	    height: 100%;
	    justify-content: center;
	    align-items: center;
	    align-content: center;
	    max-width: 360px;
	    margin: 0 auto;
	    .card-body{
	    	border: 1px solid rgba(0, 0, 0, 0.2);
		    border-radius: 0.3rem;
		    outline: 0;
			border: none;
		    border-radius: 3px;
		    background-color: #ffffff;
		    padding: 2.5rem 1.25rem
	    }

	}
}