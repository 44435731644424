#help-center-conent{
	background-image: url("/images/frontpage-bg.jpg"); 
	background-attachment: local;
	background-position: right top;
	background-size: 58% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	z-index: -1;
	overflow: hidden;
	padding-bottom:90px;
	@include breakpoint(md){
		background-image: none;
		padding-bottom: 10px;
	}
}
.main-help-searchbar{    
	padding:40px 0px 20px;
	.help-main-title{
		text-align: left;
		h1{
			text-transform: capitalize;
			font-weight: bold;
			font-size: 24px;
			color: #000326;
			@include breakpoint(md){
				font-size: 20px;
			}
			@include breakpoint(sm){
				font-size: 18px;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				color: #000326;		
				text-decoration: none;	
			}
		}
	}
	.help-main-back-btn{
		display: none;
		@include breakpoint(sm){
			text-align: left;
			a{
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				color: #8492A5;		
				text-decoration: none;		
			}
		}
	}
	.help-main-search{
		width: 100%;
		.help-main-search-field{
			width: 100%;
			background-color: #FFFFFF;
			border: 1px solid #C9D3DE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-webkit-box-shadow:0px 4px 5px rgba(59, 72, 87, 0.06);
		    -moz-box-shadow:0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			padding: 11px 16px 9px 11px;
			@include placeholder-content{
				font-weight: 600;
				font-size: 18px!important;
				color: #B0BBCB;
			}
		}
	}
}
.help-center-main-content{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	@include breakpoint(md){
		flex-direction: column;
	}
	.help-center-sidebar{
		width: 100%;
   		max-width: 270px;
   		@include breakpoint(md){
			display: none;
		}
   		.hc-sidebar-menu{
   			margin: 0;
		    padding: 0;
		    width: 100%;
		    list-style-type: none;
		    height: auto;
		    border: none;
		    .hc-menu-link{
		    	width: 100%;			   
			    white-space: normal;			      
			    a.menu-link{
			    	font-weight: bold;
					font-size: 16px;
					color: #8492A5;
					text-decoration: none;
					outline: none;	
					padding: 15px 20px 15px 0px;
					border:0px;	
					border-radius: 0px;
					border-right: 2px solid #D3DCE6;
					transition: all 0.3s ease-in-out;			
			    }
			    a.active{
			    	color: #3A76CE;
			    	border-color: #3A76CE;
			    }
		    }
		    li.active-option{
		    	border-right: 2px solid #3A76CE;
		    	.active-a{
		    		color: #3A76CE;
		    	}
		    }
   		}
	}
	.help-center-options{
		width: calc(100% - 270px);
		padding-left: 90px;
		@include breakpoint(md){
			width: 100%;
			padding-left: 0px;
		}		
		.hc-full-menu{
			margin: 0;
		    padding: 0;
		    width: 100%;
		    list-style-type: none;
		    .hc-full-menu-link{
		    	width: 100%;
			    white-space: normal;			   
   				margin-bottom: 25px;
   				@include breakpoint(md){
				    display: block;
				}
				&.tab-content{
					> .tab-pane.active{
						@include breakpoint(sm){
							display: none;
							&.mobile-show{
								display: block;
							}
						}
					}
				}
   				span.hidden-desktop{
				   	display: none;
				   	@include breakpoint(md){
				   	 	display: block;
				    }
				}
				span.show-option-button{
					padding-left: 20px;
				}
			    span.show-option-button, span.close-option-button{
				    display: block;
				    @include breakpoint(md){
					   	display: none;
					}
				 }
				.full-meni-link-content{
					margin: 0px;
					list-style-type: none;
					display: block;
					padding: 20px 0px;
					@include breakpoint(md){
					   	padding: 15px 0px 5px;
					   	border-bottom: 1px solid #D3DCE6;
					   	margin-bottom: 10px;
					}
					@include breakpoint(sm){
					   	border-color: transparent;
					   	padding: 0px;
					}
				}
				.option-main-title{
				    display: none;			   
					@include breakpoint(md){
						display: flex;
						justify-content: space-between;
				    	align-items: baseline;
				    	font-weight: bold;
						font-size: 18px;
						color:  #8492A5;
						text-decoration: none;
						outline: none;
						padding: 12px 0px;
						border-bottom: 2px solid transparent;
						.fa-plus{
							display: none;
						}
						.fa-minus{
							display: block;
						}
						.fa-chevron-right{
							display: block;
						}
						.fa-chevron-down{
							display: none;
						}
						@include breakpoint(sm){
							font-style: normal;
							font-weight: bold;
							font-size: 14px;
							color: #000326;
							border-color: transparent;
							.star,
							.fas{
								color: #B0BBCB;
							}
							&.mobile-hide{
								display: none !important;
							}
						}
						&.active{
							color: #3A76CE;
							border-color: #D3DCE6;							
							.fa-plus{
								display: block;
							}
							.fa-minus{
								display: none;
							}
							.fa-chevron-right{
								display: none;
							}
							.fa-chevron-down{
								display: block;
							}
							@include breakpoint(sm){
								font-size: 14px;
								color: #000326;
								border-color: transparent;
								.fa-chevron-right{
									color: #B0BBCB;
									display: block;
								}
								.fa-chevron-down{
									color: #B0BBCB;
									display: none;
								}
								&.mobile-opened{
									font-style: normal;
									font-weight: bold;
									font-size: 18px;
									color: #000326;
									border-color: #D3DCE6;
									padding-top: 0px;
									.fa-chevron-right{
										display: none;
									}
									.fa-chevron-down{
										display: none;
									}
								}
							}
						}						
					}
			    }
				.hc-option-title{			
					margin-bottom: 0px;					
					a.hc-option-name{
						width: 100%;
						display: flex;
				    	align-items: baseline;
				    	justify-content: space-between;
						font-weight: bold;
						font-size: 24px;										
						text-decoration: none;
						color: #3A76CE;
						padding: 12px 0px;
						border-bottom: 2px solid #3A76CE;						
						.fa-plus{
							display: none;
						}
						.fa-minus{
							display: block;
						}
						.fa-chevron-right{
							display: none;
						}
						.fa-chevron-down{
							display: block;
						}
						@include breakpoint(md){
							font-size: 16px;
							border-color: transparent;
						}
						@include breakpoint(sm){
							font-style: normal;
							font-weight: bold;
							font-size: 14px;
							color: #000326;
							border-color: transparent;
							.star,
							.fas{
								color: #B0BBCB;
							}
							.fa-plus{
								display: none;
							}
							.fa-minus{
								display: none;
							}
							.fa-chevron-right{
								display: block;
							}
							.fa-chevron-down{
								display: none;
							}							
						}
						&.collapsed{
							color: #000326;
							border-color: transparent;
							@include breakpoint(sm){
								font-style: normal;
								font-weight: bold;
								font-size: 14px;
								color: #000326;
								/*border-color: #D3DCE6;*/
							}
							.fa-plus{
								display: none;
							}
							.fa-minus{
								display: none;
							}
							.fa-chevron-right{
								display: block;
							}
							.fa-chevron-down{
								display: none;
							}
						}
						@include breakpoint(sm){
							&.mobile-hide{
								display: none !important;
							}
							&.mobile-opened{
								font-style: normal;
								font-weight: bold;
								font-size: 18px;
								color: #000326;
								border-color: #D3DCE6;
								padding-bottom: 12px;
								padding-top: 0px;
								.fa-chevron-right{
									display: none;
								}
								.fa-chevron-down{
									display: none;
								}
							}
						}					
							
					}
					.option-name-opened{
						color: #3A76CE;
						border-bottom: 2px solid #3A76CE;
						@include breakpoint(md){
							color: #000326;
							border-bottom: 0px;
						}
					}
					.mob-hc-option-content{
						position: relative;
	   					padding: 20px 0px;
	   					@include breakpoint(md){
	   						padding: 20px 0px 15px 0px;
	   					}
	   					&.show{
	   						@include breakpoint(sm){
								display: none;
								&.mobile-show{
									display: block;
								}
							}
	   					}
	   					.subtitle-content-text {
						    font-weight: 600;
						    font-size: 14px;
						    color: #000326;
						    padding-bottom: 20px;
						}
						.subtitle-content-image {
						    margin-bottom: 20px;
						    .img-hold{
							    min-width: 100%;
							    height: 129px;
								img {
								    width: 100%;
								    height: 100%;
								}
							}
						}
						p{
							margin-bottom: 15px;
							font-size: 16px;
							font-weight: 600;
							color: #8492A5;
							@include breakpoint(md){
								font-size: 14px;
		   						color:#000326;
		   					}
						}
						ul{
							list-style-type: disc;
							li{
								font-size: 16px;
								font-weight: 600;
								color: #8492A5;
								@include breakpoint(md){
									font-size: 14px;
			   						color:#000326;
			   					}
							}
						}
	   				}
				}
				/*.active{
					color: #3A76CE;
					padding-bottom: 5px;
					border-bottom: 2px solid #3A76CE;
					@include breakpoint(md){
						font-size: 14px;
						color:  #000326;
						padding-bottom: 0px;	
						border: none;
					}

				}			*/	
				.mob-hc-option-submenu{
   				  	padding-top: 20px;
   				  	@include breakpoint(md){
   				  		padding-top: 0;
   				  	}
   				   	ul.hc-options-of-option{
   				   	    list-style-type: none;
					    margin: 0;
					    padding: 0;
					    li.mob-hc-option-btn{
					    	margin-bottom: 28px;
						    display: flex;
						    justify-content: space-between;
						    align-items: baseline;
						    .help-option-text{
								font-weight: 600;
								font-size: 14px;
								color:  #000326;
								padding-right: 15px;
								text-decoration: none;
							}
					    }
   				   	}
   				}
			}
		}
		.active-title-phone{
			padding: 12px 0px;
			border-bottom: 1px solid #D3DCE6;
			font-weight: bold;
			font-size: 16px!important;
			color: #000326;
			background-color: #ffffff;
			.hidden-desktop{
				display: none!important;
			}
		}
	}
}



#help-center-opener{
	display: none;
	position: fixed;
	bottom: 25px;
	right: 20px;
	max-width: 320px;
	max-height: 440px;
	background-color: #FFFFFF;
	box-shadow: 0px 4px 12px rgba(59, 72, 87, 0.3);
	width: 320px;
	height: 0px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.8s ease-in-out;
	overflow: hidden;
	z-index: -1;
	border-radius: 4px; 
	&.opened{
		display: block;
		height: 440px;
		opacity: 1;
		visibility: visible;
		z-index: 1055;
	}
	.all-page-container{
		max-width: 320px;
		padding: 0px;
		border-radius: 4px;
		position: relative;
		.main-help-searchbar{
			background-color: #000326;
			color: #fff;
			width: 100%;
			border-radius: 4px;
			padding: 10px 0px;
			position: relative;
			display: block;
			#help-center-title{
				width: 100%;
				text-align: center;
				display: flex !important;
				justify-content: center;
				align-items: center;
				align-content: center;
				h1{
					font-weight: bold;
					font-size: 14px;
					text-align: center;
					color: #fff;
					margin: 0px;
				}
			}
			.help-main-back-btn{
				position: absolute;
				top: 5px;
				left: 15px;
				a{
					font-weight: normal;
					font-size: 12px;
					text-align: center;
					color: #fff;
					text-decoration: none;					
				}
			}
			.help-main-close-btn{
				position: absolute;
				top: 5px;
				right: 15px;
				a{
					font-weight: normal;
					font-size: 12px;
					text-align: center;
					color: #fff;
					text-decoration: none;					
				}
			}
		}
		.help-center-main-content{
			display: flex;
			padding: 15px 15px 40px 15px;
			max-width: 100%;
			overflow: auto;
			height: 405px;
			flex-direction: column;
			&::-webkit-scrollbar {
			  width: 1px;				  
			}
			&::-webkit-scrollbar-track {
			  background: transparent;
			}
			&::-webkit-scrollbar-thumb {
			  background: transparent;
			}
			.help-center-sidebar{
				display: none;
			}
			.help-center-options{
				width: 100%;
				position: relative;
				padding: 0px;
				display: block;
				.hc-full-menu{
					.hc-full-menu-link{
						width: 100%;
					    white-space: normal;			   
		   				margin-bottom: 25px;
		   				display: block;
						&.tab-content{
							> .tab-pane.active{								
								display: none;
								&.mobile-show{
									display: block;
								}							
							}
						}
						span.hidden-desktop{
						   	display: block;
						}
						span.show-option-button, span.close-option-button{
						   display: none;
						}
						.full-meni-link-content{
							border-bottom: 1px solid transparent;
							padding: 0px;
				   			margin-bottom: 10px;
						}
						.option-main-title{
							display: flex;
							justify-content: space-between;
					    	align-items: baseline;
					    	font-weight: bold;
							font-size: 18px;
							color:  #8492A5;
							text-decoration: none;
							outline: none;
							padding: 12px 0px;
							border-bottom: 1px solid transparent;
							font-style: normal;
							font-weight: bold;
							font-size: 14px;
							color: #000326;
							border-color: transparent;
							border-width: 2px;
							.star,
							.fas{
								color: #B0BBCB;
							}
							&.mobile-hide{
								display: none !important;
							}
							.fa-plus{
								display: none;
							}
							.fa-minus{
								display: block;
							}
							.fa-chevron-right{
								display: block;
							}
							.fa-chevron-down{
								display: none;
							}
							&.active{
								font-size: 14px;
								color: #000326;
								border-color: transparent;
								.fa-chevron-right{
									color: #B0BBCB;
									display: block;
								}
								.fa-chevron-down{
									color: #B0BBCB;
									display: none;
								}
								&.mobile-opened{
									font-style: normal;
									font-weight: bold;
									font-size: 18px;
									color: #000326;
									border-color: #D3DCE6;
									margin-bottom: 20px;
									padding-top: 0px;
									.fa-chevron-right{
										display: none;
									}
									.fa-chevron-down{
										display: none;
									}
								}
							}					
					    }
						.hc-option-title{			
							margin-bottom: 0px;					
							a.hc-option-name{
								width: 100%;
								display: flex;
						    	align-items: baseline;
						    	justify-content: space-between;								
								text-decoration: none;
								font-style: normal;
								font-weight: bold;
								font-size: 14px;
								color: #000326;
								border-color: transparent;	
								.star,					
								.fas{
									color: #B0BBCB;
								}
								.fa-plus{
									display: none;
								}
								.fa-minus{
									display: none;
								}
								.fa-chevron-right{
									display: block;
								}
								.fa-chevron-down{
									display: none;
								}
								&.collapsed{
									font-style: normal;
									font-weight: bold;
									font-size: 14px;
									color: #000326;
									border-color: transparent;
									.fa-plus{
										display: none;
									}
									.fa-minus{
										display: none;
									}
									.fa-chevron-right{
										display: block;
									}
									.fa-chevron-down{
										display: none;
									}
								}
								&.mobile-hide{
									display: none !important;
								}
								&.mobile-opened{
									font-style: normal;
									font-weight: bold;
									font-size: 18px;
									color: #000326;
									border-color: #D3DCE6;
									padding-bottom: 12px;
									padding-top: 0px;
									.fa-chevron-right{
										display: none;
									}
									.fa-chevron-down{
										display: none;
									}
								}				
									
							}
							.mob-hc-option-content{
								position: relative;
		   						padding: 20px 0px 15px 0px;
			   					&.show{
			   						display: none;
									&.mobile-show{
										display: block;
									}
			   					}
								p{
									font-size: 14px;
								}
								ul{		
									li{
										font-size: 14px;
									}
								}
			   				}
						}
						.mob-hc-option-submenu{
							padding: 0px;
						}
					}
				}  
			}
		}
		.appender-link{
			position: absolute;
			bottom: 0px;
			background-color: #fff;
			height: 40px;
			width: 100%;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			border-top: 1px solid #E0E6ED;			
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				width: 100%;
				height: 100%;
				text-align: center;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				color: #8492A5;
				text-decoration: underline;
			}
		}
	}

}