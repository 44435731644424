.sidebar-header-holder{
	position: relative;
    width: 100%;
    height: 100%;
    z-index: 1040;
	.sidebar-header{
		position: fixed;
	    top: 0px;
	    left: 0px;
	    width: 100%;
	    height: 100%;
	    z-index: 1001;	  
	    transition: all 0.3s ease-in-out;
	    overflow-x: hidden;
	   	overflow-y: scroll;
	   	background-color: #ffffff;
	    .sidebar-top-menu{
	    	position: relative;
		    z-index: 1055;
		    background-color: #ffffff;
		    border-bottom: 1px solid #E0E6ED;
	    	display: flex;
		    justify-content: center;
		    align-items: center;
		    align-content: center;
		    padding-bottom: 20px;
		    padding-top: 8px;
		    margin-top: 10px;
		    .close-sidebar{		    	
		    	position: absolute;
		    	display: block;
		    	top: 15px;
		    	left: 15px;
			    .sidebar-close-btn{
			    	padding: 0;
			    	border: none;
			    	border-radius: 0px;
			    	&:focus{
			    		outline: none;
			    	}
			    }	
			}
			.sidebar-logo{
				flex-basis: 100px;
				text-align: center;
			}	   
		    .sidebar-search{
				display: flex;
				justify-content: flex-end;
		    	flex-basis: 100px;
		    	padding-top: 13px;
			    .sidebar-search-form {
				    display: flex;
				    align-items: baseline;
				    .ws-icon-search{
				    	vertical-align: middle;

				    }
				    .sidebar-search-input{
				    	margin-left: 5px;
				    	font-weight: 600;
						font-size: 14px;

						color: #000326;
				    }
				}
			}
	    }
	    nav.sidebar-nav{
	    	margin-top: 20px;
	    	background-color: #ffffff;
	    	display: block;
	    	padding: 10px 15px;
	    	position: relative;
	    	width: 100%;
	    	height: auto;
	    	overflow-x: hidden;
	    	overflow-y: auto;
	    	@include breakpoint(md){
	    		padding-bottom: 0px;
	    	}	    	
	    	.sidebar-test-menu{
	    		text-align: center;	   			
	   			padding: 40px 0px;
	   			.sidebar-test-list{
	   				li{
	   					padding-top: 0px;										
						text-align: center;	
						&.see-all-tests{
							padding-top: 30px;
						}				
						a{
							font-style: normal;
							font-weight: 600;
							font-size: 14px;

							color: #000326;
							box-sizing: border-box;
						}
						a.dropdown-toggle::after {
							display: none;
						}
	   				}
	   				li.sidebar-more-tests {
					    span.space-right {
						    margin-right: 5px;
						}
						a{
							color: #8492A5;
						}
						span.space-left {
						    margin-left: 5px;
						}
						ul.sidebar-more-tests-btn{							
							@include breakpoint(md){
								border: none;
							}
							li.dropdown-element{
								text-align: center;
								overflow-wrap: break-word;
								white-space: normal;
								a{
									font-size: 12px;
									text-overflow: ellipsis;
								}
							}
							li.see-all-tests{
								a{
									color: #3A76CE;
									&:hover{
										color: #184282;
									}
								}
							}
						}
					}
				}
	    	}
	    	.user-logged{
	    		padding-top: 22px;
	    		border-top: 1px solid #E0E6ED;
	    		margin-bottom: 15px;
	    		.user-personal{
	    			display: flex;
	    			justify-content: space-between;
	    			align-items: center;
	    			padding-bottom: 20px;
	    			.user-data{
	    				display: flex;
	    				align-items: center;
	    				.user-img{
	    					width: 28px;
						    height: 28px;
						    border-radius: 50%;
						    overflow: hidden;
	   						 position: relative;
	   						 margin-right: 8px;
						    img{
						    	display: inline;
						    	margin: 0 auto;
						    	max-width: 100%;
						    	height: 100%;
						    }
	    				}
	    				.user-name{
		    				span{
		    					font-weight: 600;
								font-size: 14px;

								color: #000326;
		    				}	
	    				}
	    			}
	    			.change-log-status{
	    				font-weight: 600;
						font-size: 12px;
						color: #8492A5;
						a{
							font-weight: 600;
							font-size: 12px;
							color: #8492A5;
						}
	    			}
	    		}
	    		.user-profile-access,
	    		.user-tests-access{
	    			padding-bottom: 20px;
	    		}
	    		.user-links{
	    			font-weight: 600;
					font-size: 14px;

					color: #000326;
					text-transform: capitalize;
	    		}
	    	}	    	
	    }  	    
	}	
	.ws-btn{
		display: block;	
		margin-bottom:15px;
		text-align: center;				
		.test-button{
			border: 1px solid #3A76CE;
			box-sizing: border-box;
			border-radius: 3px;
			background-color: transparent;		
			padding: 9px 16px;
			color: #3A76CE;
			font-weight: 600;
			font-size: 14px;
			text-align: center;
			transition:all 0.3s ease-in-out;
			outline: none;
			display: block;
			width: 100%;
			margin: 0 auto;
			&:hover{
				border: 1px solid #184282;
				color: #184282;
			}
		}

	}
	.ws-login{
		display: block;	
		margin-bottom: 25px;				
		.login-button{
			border: 1px solid #3A76CE;
			box-sizing: border-box;
			border-radius: 3px;
			background-color: transparent;		
			padding: 9px 16px;
			color: #3A76CE;
			font-weight: 600;
			font-size: 14px;
			text-align: center;
			transition:all 0.3s ease-in-out;
			outline: none;
			display: block;
			width: 100%;
			&:hover{
				border: 1px solid #184282;
				color: #184282;
			}
		}

	}
	.sidebar-support{
	    text-align: center;
	    padding: 16px 0px;
	    border-top: 1px solid #E0E6ED;
	    background-color: #fff;
	    margin: 0 15px;
	    margin-top: 30px;
	    
	}
	.sidebar-support-link{
	  	a{
	  		font-weight: 600;
			font-size: 14px;
			color:  #8492A5;
			text-transform: capitalize;
	   	}
	}
}
.show{
	transition: all .3s ease-in-out;
}