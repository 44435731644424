.write-popup-window{
	max-width: 512px;
	width: 100%;
	display: table;
	height: auto;
	margin: 150px auto;
	@include breakpoint(xs){
		max-width: 100%;
		margin: 90px auto auto;
	}
}
.popup-modal-content{
	overflow:auto;
	border: none;	
	border-radius: 3px;
	background-color: #ffffff;
	height: 100%;
}
.close-popup-window{
	background-image: url('/images/close-bg.png');
	width: 62px;
	height: 62px;
	border: none;
	position: relative;
	opacity: 1!important;
	transition: all 0.3s ease-in-out;
	&:focus{
		outline: none;
		border: none;
	}
	&:hover{
		span.closing-button{
			svg{
				path{
					fill:  #184282;
				}
			}
		}
	}
	span.closing-button{
		position: absolute;
	    top: 5px;
	    right: 12px;
	}
}
.popup-modal-body{
	padding: 0 60px 60px 60px;	
	.ws-logo{
		display: flex;
		text-align: center;
		width: 100%;
		margin: 0px auto 30px;
		a{
			display: block;
			min-height: 72px;
			margin: 0 auto;
		}
	}	
}
.popup-button{
	width: 100%;
	height: 45px;
	padding: 10px 20px 10px 20px;
	background-color: #3A76CE;
	-webkit-box-shadow:0px 4px 6px rgba(48, 77, 119, 0.2);
	-ms-box-shadow:0px 4px 6px rgba(48, 77, 119, 0.2);
	box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
	border-radius: 4px;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	color: #FFFFFF;
	border: none;	
	&.white-text{
		color:#fff !important;
	}
}
.popup-centered{
	width: 100%;
	text-align: center;
}
#LoginModal{
	.write-popup-window{
		max-width: 435px;		
	}
	.popup-modal-body{
		max-width: 316px;
		width: 100%;
	    margin: 0 auto;
	    padding: 0px 25px 60px 25px;
		@include breakpoint(xs){
			max-width: 100%;
		}
	}

}
#progress-popup-window{
	max-width: 572px;
	width: 100%;
	height: 100%;
	position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    margin-top: 0px;
    background-color: #ffffff;
    &.series-7{
    	max-width: 765px;
    }
    @include breakpoint(md){
    	overflow: scroll;
    	margin-right: 0px;
    	max-width: 100%;
    } 
    .popup-modal-content{
    	border: none;
    }
    .question-result-template{
    	justify-content: space-between;
	    .ordered-on-mobile{
	    	display: flex;
	    	@include breakpoint(sm){
	    		flex-direction: row;
	    		flex-wrap: wrap;
	    	}
	    	.results-pattern{
	    		margin-bottom: 20px;
	    	}
	    }
	}
}	
.progress-modal-body{
	padding: 0px 40px 75px 40px;
	margin-top: -25px;
	@include breakpoint(xs){
		padding-left: 15px;
		padding-right: 15px; 
	}
	 #progress-questions-result{
    		.question-result-type{
    			margin-right: 15px;
    		}
    }
}
.progress-title{
	display: flex;
    align-items: baseline;
    justify-content: space-between;
    @include breakpoint(xs){
    	flex-direction: column;
    }
    .title{
    	padding-bottom: 15px;
	    span{	
	    	font-weight: bold;
			font-size: 20px;
			color: #000326;
		}
    }
    .units-title{
    	padding-bottom: 15px;
    }   
}	
.success-title{
	text-align: center;	
	padding-bottom: 12px;
	margin-bottom: 28px;
	border-bottom: 1px solid #D3DCE6;
	@include breakpoint(sm){
		white-space: normal;
		text-align: center;
	}
	span{
		font-weight: bold;
		font-size: 20px;
		text-align: center;
		color: #16BD81;
	}
}
.r-success-message-show{
	text-align: center;
	.r-success-message{
		span{
			font-weight: 600;
			font-size: 16px;
			text-align: center;
			color: #8492A5;			
		}
	}
}

.error-title{
	text-align: center;		
	padding-bottom: 12px;
	margin-bottom: 28px;
	border-bottom: 1px solid #D3DCE6;
	@include breakpoint(sm){
		white-space: normal;
		text-align: center;
	}
	span{
		font-weight: bold;
		font-size: 20px;
		text-align: center;
		color: #EC6161;
	}
}
.r-error-message-show{
	text-align: center;
	.r-error-message{
		span{
			font-weight: 600;
			font-size: 16px;
			text-align: center;
			color: #EC6161;			
		}
	}
}
.popup-modal-title{
	text-align: center;
	margin-bottom: 30px;
	.title-1{
		span{
			font-weight: bold;
			font-size: 24px;
		    text-align: center;
		    color: #000326;
		}
	}
	.title-2{
		span{
			font-weight: 600;
			font-size: 14px;
			text-align: center;
			color: #000326
		}
	}
}
.set-review-rating{
	margin-bottom: 35px;
	.set-review-title{		
		padding-bottom: 13px;
		legend,
		span{
			font-weight: bold;
			font-size: 14px;
			color:  #000326;
		}
	}
	.set-rating-stars{
		margin-bottom: 30px;
		.rating-stars{
			position: relative;
			display: block;
			width: 150px;
			height: 20px;							
			#_1 {
			  right: 120px;
			}
			#_2 {
			  right: 90px;
			}
			#_3 {
			  right: 60px;
			}
			#_4 {
			  right: 30px;
			}
			#_5 {
			  right: 0px;
			}
			.rb {
			  width: 30px;
			  height: 20px;
			  margin: 0px;
			  position: absolute;
			  opacity: 0;
			  cursor: pointer;
			  z-index: 1;
			  direction: rtl;
			  ~ span{
			  	width: 30px;
			  	text-align: left;						
			  	float: right;
			  }
			  ~ .fas{
			  	display: none;
			  }
			  &:checked ~ span.far,						
			  &:hover	~ span.far{
			  	display:none;
			  }	
			  &:checked ~ span.fas,						
			  &:hover	~ span.fas{
			  	display:block;
			  }	
			}

			.rb:checked ~ span {
			  color: #3A76CE;
			}

			.rb:hover ~ span {
			  color: #3A76CE;
			}

			.rb:hover ~ .rb:checked ~ span {
			  color: #3A76CE;
			}

			.rb:checked:hover ~ span {
			  color: #3A76CE;
			}

			.rb:checked:hover {
			  cursor: default;
			}

			.rb:checked ~ .rb:hover ~ span.fas {
			  color: #D3DCE6;
			}
			.rb:checked ~ .rb:hover ~ span.far {
			  color: #3b3;
			}
		}
	}

}
.set-review-message{
	margin-bottom: 20px;
	.set-review-title{
		display: flex;
		font-weight: bold;
		font-size: 14px;
		color:  #000326;
		legend{
			font-weight: bold;
			font-size: 14px;
			color:  #000326;
		}
	}
	#set-rev-msg{
		width: 100%;
	    resize: none;
	    height: 120px;
	    background: #FFFFFF;
	    border: 1px solid #C9D3DE;
	    box-sizing: border-box;
	    -webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	    -ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	    box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	    border-radius: 4px;
	    margin-bottom: 20px;
	}
}
#start_exam,
.start_exam{
	.start-exam-body{
		max-width: 375px;
	    margin: 0 auto;
	    padding: 0px 25px 60px 25px;
		@include breakpoint(xs){
			max-width: 100%;
		}
		.exam-title-2{
			span{
				color: #8492A5;
			}
		}
		.popup-title-3{
			width: 100%;
			text-align: center;
			padding: 6px 0 16px 0;
			span{
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #000326;
			}
		}
	}
}
.full-width-btn{

}