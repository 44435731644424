@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,400,600,700,800,900&display=swap');
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

$owl-image-path: "/node_modules/owl.carousel/src/img/";
@import "node_modules/owl.carousel/src/scss/owl.carousel.scss";

@import "mixins";
@import "help_center";
@import "~bootstrap/scss/bootstrap";
@import "custom";
@import "header/header";
@import "header/sidebar_header";
@import "footer";
@import "dropzone";
@import "frontpage";
@import "all_tests";
@import "test_page";
@import "login";
@import "profile";
@import "training";
@import "question-answer-page";
@import "result";
@import "popups";
@import "products";
@import "search";



.grecaptcha-badge { visibility: hidden; }