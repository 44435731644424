p{
	margin: 0px;
}
.test-page{
	.test-info-title{
		font-weight: bold;
		font-size: 24px;
		color: #000326;
		@include breakpoint(md){
			font-size: 20px;
		}

	}
	.test-rating-holder{
		font-weight: 600;
		font-size: 16px;
		color:  #8492A5;
		display: flex;
		flex-direction: column;
		margin:7px 0 10px; 
		.review-stars{
			font-size:12px;
			color: #3A76CE;
		}
	}
	.test-info{
		padding-bottom: 80px;
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		@include breakpoint(md){
			flex-wrap: wrap;
		}
		.current-test-name {
			max-width: 782px;
			margin-right: auto;
			width: 100%;
			@include breakpoint(md){
				max-width: 100%;
				margin-bottom: 35px;
			}			
			.test-info-content{
				p.test-text-holder{
					font-weight: 600;
					font-size: 16px;
					color:  #8492A5;
				}
				.form-inline{
					margin-top: 12px;
					flex-wrap: nowrap !important;
					input{
						outline:none;
						box-shadow: none;
						&:hover,
						&:focus{
							outline:none;
							box-shadow: none;
						}
					}
					.btn{
						background: #3A76CE;
					    border-color: #3A76CE;
					    margin-right: 0px;
					    margin-left: 8px;
					    cursor: pointer;
					}
				}
				ul{
					display: flex;
					width: 100%;
					padding: 32px 0;
					position: relative;
					list-style: none;
					flex-direction: column;
					margin: auto;
					li{
						display: flex;
						width: 100%;
						position: relative;
						align-items: center;
						align-content: center;
						padding-bottom: 12px;
						margin: 2px 0px;
						z-index: 1;
						a{
							font-weight: 600;
							font-size: 16px;
							color: #8492A5;
							margin: 0px;
							display: flex;
							align-content: center;
							align-items: center;
							.custom-icon{
								position: relative;
								width: 22px;
								margin-right: 7px;
								height: 22px;
								justify-content: center;
								display: flex;
								align-content: center;
								align-items: center;
								position: relative;
								margin-left: 3px;
								top: auto;
								bottom: auto;
								right: auto;
								left: auto;
							}
						}

					}
				}
			}
		}
		.current-test-duration{
			margin-left: 20px;
			padding:40px 0px 2px 0px;
			.same-style{
				border-left: 2px solid #D3DCE6;
				padding-left: 15px;
				padding-bottom: 10px;				
				&:last-child{
					padding-bottom: 0px;
				}				
			}
			.same-style, .test-duration-num{
				span{
					font-weight: bold;
					font-size: 14px;
					color:  #000326;
				}
			}
		}
	}
	.test-mode{
		padding-bottom: 68px;
		.test-mode-title{			
			padding-bottom: 15px;
			span{
				font-weight: bold;
				font-size: 18px;
				color:  #000326;
			}
		}
		.test-mode-content{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 32px;
			width: 100%;
			height: 100%;
			background-color: #3A76CE;
			border-radius: 4px;
			margin-bottom: 12px;
			@include breakpoint(sm){
				flex-direction: column;
			}
			.mode-bread-crumbs{
				max-width: 250px;
				width: 100%;
				margin: 0px 20px;
				@include breakpoint(md){
				    width: 100%;
				    margin-bottom: 40px;
				}
				.bread-crumbs{
					display: inline-flex;
					font-weight: 600;
					font-size: 12px;
					color: #FFFFFF;
					margin-bottom: 15px;
					.sing-betwwen{
						margin: 0 3px;
					}
				}
				.mode-time-duration{
					.time-lasting{
						border: 1px solid #6E99DA;
						box-sizing: border-box;
						border-radius: 4px;
						padding: 2px 8px 4px 8px;
						svg{
							path{
								fill: #ffffff;
							}
						}
						.duration-text{
							font-weight: 300;
							font-size: 12px;

							color: #FFFFFF;
							margin-left: 2px;
						}
					}
					#traning-mode-duration{
						svg{
							display: none;
						}
						.duration-text{
							font-weight: 600;
							font-size: 12px;

							color: #B0C8EB;
						}
					}
				}
			}
			.test-mode-explained{
				display: flex;
				max-width: 398px;
				width: 100%;
				margin-right: auto;
				@include breakpoint(md){
					max-width: 100%;
    				margin-bottom: 32px;
				}
				.white-line{
					width: 22px;
					height: 2px;
					background-color: #ffffff;
					margin-top: 15px;
					margin-right: 10px;
					display: inline-block;
				}
				.mode-name{					
					padding-bottom: 6px;
					display: flex;
					align-items: center;
					align-content: center;					
					strong,
					span{
						font-weight: 600;
						font-size: 24px;
						color: #FFFFFF;
						&.custom-icon{
							margin: 0 0 0 8px;
						}
					}
					label{
						margin: 0 0 0 8px;
						background: #B0C8EB;
						border-radius: 4px;
						padding: 4px 8px;
						display: inline-block;
						font-weight: bold;
						font-size: 12px;
						color: #3A76CE;
					}
				}
				.mode-explanation{
					p{
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						color: #B0C8EB;
					}
				}
			}

			.test-mode-btn{
				max-width: 158px;
				width: 100%;
				margin-left: auto;
				@include breakpoint(md){
					margin: auto;
				}
				@include breakpoint(sm){
					max-width: 100%;
				}
				button.start-button{
					width: 100%;
					height: 45px;
					border: none;
					background-color: #ffffff;
					font-weight: 600;
					font-size: 18px;
					text-align: center;
					color: #3A76CE;
					box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
					-ms-box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
					-webkit-box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
					border-radius: 4px;
					transition: all 0.3s ease-in-out;
					&.scrollable-button{
						background-color: #16BD81;
						color: #fff;
						&:hover{
							background-color: #0cc07f;
							color: #fff;
						}
					}
					&:hover{
						color: #184282;
					}
					@include breakpoint(md){
						width: 100%;
					}
				}
			}
		}
	}
	.exam-question-table{
		margin-top:15px;
		padding-bottom: 65px;
		.table-title{
			padding-bottom: 30px;
				span{
					font-weight: 600;
					font-size: 16px;
					color:  #000326;
				}
		}
		.table-content{
			position: relative;
			display: flex;
			width: 100%;
			justify-content: space-between;
		    @include breakpoint(md){
		    	overflow: scroll;
		    	flex-wrap: nowrap;
		    }
		    dl,
		    ul{
		    	margin: 0;
		    	padding: 15px 0px;
		    	list-style-type: none;
		    }
		    dt,
		    dd,
		    li{	    	
		    	font-weight: 600;
				font-size: 14px;
				color: #000326;
				min-height: 68px;
				max-height: 68px;
				border-bottom: 1px solid #E0E6ED;
				margin: 0px;
				overflow: hidden;
  				text-overflow: ellipsis;
  				line-height: 1.3;
				&.has-border{
					border-top: 1px solid #000326;
					border-bottom: 1px solid transparent !important;
				}
				&.no-border{
					border-bottom: 1px solid transparent !important;
				}
		    }
		    dt.li-title,
		    dd.li-title,
		    li.li-title{
		    	border-bottom: 2px solid #AAB5C4;
		    	font-weight: bold;
				font-size: 14px;
				text-align:left;
				color: #000326;
				padding-bottom: 12px !important;
				min-height: 30px;
				padding-top: 0px;
		    }
		    dt.li-end,
		    dd.li-end,
		    li.li-end{
		    	font-weight: bold;
				font-size: 14px;
				color: #000326;
				display: block;
			}			
		    ul.units{
		    	max-width: 90px;
		    	width: 100%;
		    	border: none;
		    	border-radius: 4px;
		    	border-top: 2px solid transparent;
		    	border-bottom: 2px solid transparent;
		    	@include breakpoint(sm){
				    position: sticky;
				    top: 0;
				    left: 0;
				    z-index: 9;
				    background-color: #ffffff;
				    box-shadow: 6px 0px 7px rgba(59, 72, 87, 0.13);
				    -ms-box-shadow:6px 0px 7px rgba(59, 72, 87, 0.13);
					-webkit-box-shadow:6px 0px 7px rgba(59, 72, 87, 0.13);
					border-color: rgba(59, 72, 87, 0.13);
		    	}
		    	li{
		    		padding:12px 20px;
		    		&.li-end{
		    			padding: 12px 20px;
				    	display: flex;
					    align-items: center;
		    		}
		    	}
		    }
		    dl.job-functions,
		    ul.job-functions{
		    	max-width: 100%;
		    	width: 100%;
		    	border: none;
		    	border-radius: 4px;
		    	border-top: 2px solid transparent;
		    	border-bottom: 2px solid transparent;
		    	@include breakpoint(sm){
		    		max-width: 100%;
		    		min-width: 520px;
		    		position: relative;
		    		background-color: #ffffff;
		    	}
		    	dd,dt,li{
		    		padding: 12px 0px;
		    		@include breakpoint(sm){
		    			padding-left: 20px;
		    		}
		    	}
		    	dt.unit-name{
			    	display: none;
			    }
		    	dd.li-title,
		    	dt.li-title,
		    	li.li-title{
		    		@include breakpoint(sm){
		    			padding-left: 20px;
		    		}
		    	}
		    }
		    dl.test-percentage,
		    ul.test-percentage{
		    	max-width: 220px;
		    	width: 100%;
		    	border: none;
		    	border-radius: 4px;
		    	border-top: 2px solid transparent;
		    	border-bottom: 2px solid transparent;
		    	@include breakpoint(sm){
		    		max-width: 100%;
		    		min-width: 240px;
		    		background-color: #ffffff;
		    	}
			    dd,dt,li{
					padding: 12px 0px;
			    	text-align: center;
			    	display: flex;
				    align-items: center;
				    justify-content: center;
			    }
			    dt.unit-name{
			    	display: none;
			    }
		    }
		    dl.num-of-questions,
		    ul.num-of-questions{
		    	max-width: 220px;
		    	width: 100%;
		    	border: none;
		    	border-radius: 4px;
		    	border-top: 2px solid transparent;
		    	border-bottom: 2px solid transparent;
		    	@include breakpoint(sm){
		    		max-width: 100%;
		    		min-width: 240px;
		    		background-color: #ffffff;
		    	}
		    	dd,dt,li{
					padding: 12px 0px;
			    	text-align: center;
			    	display: flex;
				    align-items: center;
				    justify-content: center;
			    }
			    dt.unit-name{
			    	display: none;
			    }
		    }
		    dd.li-title,
		    dd.li-end{display: none !important;padding: 0 !important;margin: 0 !important;height: 0px;}
		}
	}	
}
.try-tests{
	.try-tests-title{		
		padding-bottom: 20px;
		span{
			font-weight: bold;
			font-size: 16px;

			color: #000326;
		}
	}
}
.try-training-tests{
	.test-max-time{
		display: none!important;
	}
}