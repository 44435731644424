footer{
	position: absolute;
    height: 75px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    margin: 0 auto;
    z-index: 99;
    bottom: 0px;
    display: flex;
    align-items: center;
    background-color: transparent !important;
	@include breakpoint(md){
		padding-top: 20px;
		height: auto;
		position: relative;
		 background-color: #fff !important;
	}
	.frontpage-footer{
		display: block;
		width: 100%;	    	
    	.footer-middle-container{
    		display: block;
			width: 100%;
			max-width: 1140px;	 
			padding: 0px 15px;
			position: relative;
			margin: 0 auto;
	    	.footer-middle-content{
	    		height: 75px;
	    		display: flex;
	    		@include breakpoint(md){
	    			height: auto;
				    flex-direction: column;
				    align-items: center;
	    		}
	    		.conditions{
	    			display: flex;
	    			justify-content: flex-start;
	    			align-items: center;
	    			@include breakpoint(md){
	    				flex-direction: column;
	    			}
	    			.privacy-policy, .terms, .pricing{
	    				font-weight: 600;
						font-size: 14px;
						color: #8492A5;	
						a{
							font-weight: 600;
							font-size: 14px;
							color: #8492A5;	
							&:hover,
							&:focus{
								color: #3A76CE;
							
							}
						}
						@include breakpoint(md){
							padding-bottom: 35px;
						}				
	    			}
	    			.privacy-policy{
	    				margin-right: 35px;
	    				@include breakpoint(md){
	    					margin-right: 0px;
	    				}
	    			}
	    			.pricing{
	    				margin-left: 35px;
	    				@include breakpoint(md){
	    					margin-left: 0px;
	    				}
	    			}
	    		}
	    		.go-top-quick{
	    			position: absolute;
				    bottom: 0px;
				    left: 0;
				    right: 0;
				    margin: 0 auto;
				    width: 17px;
				    display: flex;
				    justify-content: center;
				    align-items: center;
				    align-content: center;
				     @include breakpoint(md){
				    	position: relative;
					    width: 50px;
					    height: 50px;
					    border-radius: 50%;
					    border: 1px solid #E0E6ED;
					    box-sizing: border-box;
					    box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
					    text-align: center;
				    }
				    .go-top-arrow{
				    	@include breakpoint(md){
								vertical-align: middle;
				    	}
				    }					   			
	    		}
	    	}
	    }
	}
	
}


.need-help-container{
	display: flex;
	align-items: center;
	position: fixed;
	width: 30px;
	height: 70px;
	bottom: 30px;
	right: 20px;
	white-space: nowrap;
    align-content: center;
    justify-content: center;
    cursor: pointer;	
    z-index:100;
	@media (min-width: 1539px) {
		width: 40px;
	}
	@media (max-width: 1339px) {
		display: none;
	}
	.need-help{			
	   	-moz-transform:rotate(-90deg);  
		-webkit-transform:rotate(-90deg);  
		-o-transform:rotate(-90deg);  
		-ms-transform:rotate(-90deg);
		.help-btn{
			text-decoration: none;
			span{
				font-weight: 600;
				font-size: 14px;
				text-align: center;
				color: #000326;						
				transition: all 0.3s ease-in-out;
				text-decoration: none;
				&:hover{
					color: #3A76CE;
				}
			}
		}
	}

}

.one-page-scroll{
	display: flex;
	align-items: flex-end;
	position: fixed;
	width: 30px;
	height: 70px;
	bottom: 30px;
	left: 20px;
	z-index:100;
	@media (min-width: 1539px) {
		width: 40px;
	}
	@media (max-width: 1339px) {
		display: none;
	}
	.page-parts-id{
		display: flex;
	    align-self: flex-start;
	    height: 100%;
		.part-bg{
			width: 2px;
			height: 100%;
			background-color: #D3DCE6;
		}
		#scroll-progress-bar{
			width: 4px;
			height: 100%;
			background: #3A76CE;
			border-radius: 15px;
			margin-left: -1px;
		}
	}
	.page-counter{
		margin-left: 5px;
		@media (min-width: 1539px) {
			margin-left: 15px;
		}
		span{	    			
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			color: #D3DCE6;
			vertical-align: text-bottom;
		}
		span.active-part{
			color: #3A76CE;
		}
	}
}