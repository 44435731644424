.results-showed{
	max-width: 692px;
	margin-right: auto;
	margin-bottom: 0px;
	width:100%;
	padding-right:30px;
	@include breakpoint(sm){
		max-width: 100%;
	   	margin-bottom: 0px;
	   	padding-right:0px;
	   	padding-top:20px;
	   	order:2;
	}
	.test-result-success{
	   	.title-test-completed{
	   		margin-bottom: 15px;
	   		h1{
	   			margin: 0px;
	   			font-weight: bold;
				font-size: 24px;
				color: #000326;
	   		}
	   	}
	   	.failed-or-passed{
	   		margin-bottom: 8px;
	   		h2{
	   			margin: 0px;
	   			font-weight: 600;
				font-size: 36px;
				svg {
					opacity: 0;
					visibility: hidden;
					transition: all 0.6s ease-in-out;
					&.animation-complete{
						opacity: 1;
						visibility: visible;
					}
			      path {
			        fill: none;
			        stroke: #16BD81;
			        stroke-width: 2;
			        stroke-linecap: round;
			        stroke-linejoin: round;
			        stroke-miterlimit: 10;
			      } 
			    }
	   		}
	   		h2.passed{
	   			color: #16BD81;
	   			svg {
			      path {
			        stroke: #16BD81;
			      } 
			    }
	   		}
	   		h2.failed{
	   			color: #EC6161;
	   			svg {
			      path {
			        stroke: #EC6161;
			      } 
			    }
	   		}
	   	}
	   }			
	.result-text{
		padding-bottom: 40px;
		span{
			font-weight: bold;
			font-size: 16px;
			color: #8492A5;
		}
	}
	.passed-exam-text{
		color: #16BD81;
	}
	.result-percents{ 
		position: relative;	
		padding-bottom: 108px;
		margin-bottom: 25px;						   
	    .all-answers{
	    	position: absolute;
		    width: 0%;
		    height: 4px;
		    background-color: #D3DCE6;
		    border-radius: 21px;
		    z-index: 1;
		    top: 0;
		    left: 0;
		    .all-answers-text{
		    	 padding-top: 10px;
		    }
		    .all-answers-percents, .percents-explained{
		    	opacity: 0;
		    	visibility: hidden;
		    	transition: all 0.4s ease-in-out;
		    	&.animation-complete{
		    		opacity: 1;
		    		visibility: visible;
		    	}
				span { color: #8492A5;}
			}
	    }
	    .percents-with-text{
	    	position: absolute;
		    right: 0px;
		    top: 0px;				   
		    text-align: right;
			font-weight: bold;
			font-size: 12px;
			opacity: 0;
	    	visibility: hidden;
	    	line-height: 1;
	    	transition: all 0.4s ease-in-out;
	    	&.animation-complete{
	    		opacity: 1;
	    		visibility: visible;
	    	}	
	    }
	    .needed-percents{
	    	position: absolute;
		    width: 0%;
		    height: 4px;
		    background-color: #8492A5;
		    border-radius: 21px;
		    z-index: 2;
		    top: 0;
		    left: 0;
		    .need-to-pass-text{
		    	color: #8492A5;
		    	padding-top: 35px;
			    padding-right: 12px;
			    border-right: 2px solid #8492A5;
		    }
		    .percents-explained, .need-to-pass{
		    	opacity: 0;
		    	visibility: hidden;
		    	transition: all 0.4s ease-in-out;
		    	&.animation-complete{
		    		opacity: 1;
		    		visibility: visible;
		    	}
			}
	    }
	    .your-score{
	    	position: absolute;
		    top: 0;
		    left: 0;
		    width: 0%;
		    height: 4px;
		    background: #EC6161;
		    border-radius: 21px;
		    z-index: 3;
		    .user-score{
		    	position: absolute;
			    right: 0;
			    top: 0px;
			    padding-top: 10px;
			    padding-bottom: 5px;
			    padding-right: 12px;
			    text-align: right;
			    border-right: 2px solid #EC6161;
			    color:  #EC6161;
			    font-weight: 600;
				font-size: 12px;
				white-space: nowrap;
		    }
	    }
	    .user-percents, .need-to-pass, .all-answers-percents{
	    	font-weight: bold;
	    }
	}
}	
.try-again-btns{
		max-width: 242px;
		margin-left: auto;
		width: 100%;
		@include breakpoint(sm){
			max-width: 100%;
			margin: 0px;
		}
		.reload-test{
			margin-bottom: 16px;
		}
		button.same-style-btns{
			border: none;
			outline: none;
			background: #FFFFFF;
			border: 1px solid #C9D3DE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			width: 100%;
			font-weight: 600;
			font-size: 14px;
			color: #3A76CE;
			padding: 9px 15px 7px 15px;
		}
		button.take-test-again, button.try-exam-mode{
			transition: all 0.3s ease-in-out;
			path{
				transition: fill 0.3s;
			}
			&:hover{
				border: 1px solid #B0BBCB;
				color: #184282;
				svg{
					path{
						fill: #184282;
					}
				}
			}
		}
}
