h4{
	margin: 0px;
}
.training-content{	
}
.second-title{
	display: flex;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 44px;
	@include breakpoint(sm){
		flex-direction: column;
		padding-top: 5px;
		padding-bottom: 15px;
	}	
	.test-buttons{
		width: auto;
		margin-left: auto;
		text-decoration: none;
		.test-buttons-style{			
			font-weight: 600;
			font-size: 14px;
			color: #3A76CE;
			border: 0px;
			outline: 0px;
			background-color: #ffffff;
			vertical-align: middle;
			margin-right: 25px;				
			&:last-child{
				margin-right: 0px;
			}
			@include breakpoint(sm){
				max-width: 158px;
    			width: 100%;				
				font-size: 12px;
				padding: 7px 4px;
				border: 1px solid #C9D3DE;
				box-sizing: border-box;
				box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
				-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
				-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
				border-radius: 4px;
			}	
			@media (max-width: 359px) {
				font-size: 10px;
				max-width: 142px;
			}		
			@include breakpoint(xs){
				margin-right: auto;
			}
		}
		@include breakpoint(sm){
			order: 1;
			width: 100%;
			margin-bottom: 10px;
		}
		@include breakpoint(xs){
			display: flex;
			justify-content: space-between;
		}
	}
}

.line-between-counted, .count-answered-q{
	font-weight: bold;
	font-size: 36px;
	color: #B0BBCB;
	@include breakpoint(md){
		font-size: 20px;
	}
}
.count-correct-q{
	color: #000326;
}
.current-test-units{
	width: auto;
	margin-right: auto;
	&.to-right{
		margin-right: 10px;
		margin-left: auto;
		display: flex;
		justify-content: flex-end;
		@include breakpoint(sm){
			margin-right: auto;
			margin-left: auto;
			justify-content: center;
			padding: 10px 0px;
		}
	}
	@include breakpoint(sm){
		width: 100%;
		order: 2;
		margin-top: 5px;
	}
	label.unit{
		display: inline;
		margin: 0px;
		border-radius: 50px;
		text-align: center;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		color: #8492A5;
		padding: 2px 8px 1px 8px;
	}
	label.current-unit{
		background: #D5E6FF;
		color: #3A76CE;
	}
	li{
		display: inline;
		margin: 0px;
		list-style: none;
		min-width: 30px;
		min-height: 30px;
		a{
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			min-width: 30px;
			margin:1px;
			border-radius: 50px;
			text-align: center;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			color: #8492A5;
			padding: 5px 8px 4px 8px;
			&:hover,
			&:focus,
			&.current{
				background: #D5E6FF;
				color: #3A76CE;
			}
		}
	}
}

.mobile-showed{
	display: none;
	@include breakpoint(md){
		display: block;
	}
	#smallest-version{
		max-width: 100px;
		width: 100%;
		.time-countdown{
			width: 100%;
			background: #E0E6ED;
			border-radius: 4px;
			padding: 2px 8px;
			.timer{
				font-weight: 600;
				font-size: 20px;
				color: #000326;
			}
		}
	}				
}
.popup-showed,
.desktop-showed{
	display: block;
	margin-bottom: 40px;
	@include breakpoint(md){
		/*display: none;*/
		margin-bottom: 20px;
	}
	.countdown{
		max-width: 180px;
		width: 100%;
		margin: auto;
		.time-countdown{
			width: 100%;
			background: #E0E6ED;
			border-radius: 4px;
			padding: 6px 16px;
			text-align: center;
			.timer{
				font-weight: 700;
				font-size: 36px;
				color: #000326;
				label{
					margin: 0px;					
				}
			}
		}
	}
}