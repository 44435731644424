
.question-part{
	max-width: 692px;
	margin-right: auto;
	margin-bottom: 10px;
	width:100%;
	padding-right:0px;
	position: relative;
	&.no-max-width{
		max-width: 100%;
	}
	&.centered-screen{
		margin: 0 auto;
	}
	@include breakpoint(sm){
		max-width: 100%;
	   	margin-bottom: 0px;
	   	padding-right:0px;
	   	order:2;
	}
	.answer-result{
		position: relative;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;		
		.answer-container{
			margin-bottom: 40px;
		    margin-right: auto;		    
		    display: flex;
			flex-shrink:1;
			&.flex-glow{
				flex-grow:1
			}
			> mark.key-identifier{
				position: absolute;
			    left: -5px;
			    font-style: normal;
			    font-weight: 600;
			    font-size: 14px;
			    color: #8492A5;
			    background-color: #fff;
			    top: -11px;
			    padding: 0 5px 1px 0;
			    border-radius: 10px;
			}
		}
	}
	.test-current-question{
		margin-bottom: 36px;	
		span.question-number, p.question-content,h1.question-content{
			font-weight: bold;
			font-size: 20px;
			color: #000326;
		}
		span.question-number{
			margin-right: 3px;
		}
	}
	.questions-nav-buttons{
		display: flex;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 60px;
		.btn-previous, .btn-skip, .btn-flag, .btn-next, .btn-review, .btn-results{
			height: 45px;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			text-align: center;
			text-decoration: none;
			display: flex;
			align-content: center;
			justify-content: center;
			align-items: center;
		}
		.btn-previous{
			width: 100%;			
			max-width: 72px;	
			background: #D5E6FF;				
			margin-right: 28px;
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
			span.go-back-arrow{
									
			}
			@include breakpoint(sm){
				min-width: 72px;
				margin-right: 15px;
			}
		}
		.btn-review{
			width: 100%;
			max-width: 125px;
			background: #FFFFFF;
			border: 1px solid #3A76CE;
			margin-right: 28px;
			@include breakpoint(sm){
				margin-right: 15px;
			}
			@include breakpoint(xs){
				max-width:125px;
				min-width: 72px;
				margin-right: 15px;
			}
			span{
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #3A76CE;
				@media (max-width: 359px) {
					font-size: 14px;
				}
			}
		}
		.btn-results{
			width: 100%;
			max-width: 125px;
			background: #3A76CE;
			border-color: #3A76CE;
			margin-right: 0px;
			@include breakpoint(sm){
				margin-right: 15px;
			}
			@include breakpoint(xs){
				max-width:100%;
				min-width: 72px;
				margin: 15px auto;
			}
			span{
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #FFF;
				@media (max-width: 359px) {
					font-size: 14px;
				}
			}
		}
		.btn-skip{
			width: 100%;
			max-width: 72px;
			background: #FFFFFF;
			border: 1px solid #3A76CE;
			margin-right: 28px;
			@include breakpoint(sm){
				margin-right: 15px;
			}
			@include breakpoint(xs){
				max-width:165px;
				min-width: 72px;
				margin-right: 15px;
			}
			span{
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #3A76CE;
				@media (max-width: 359px) {
					font-size: 14px;
				}
			}
			&.flagged-question{
				background-color: #D5E6FF;
				color: #3A76CE;
				border-color: #3A76CE;
			}
		}
		.btn-flag{
			width: 100%;
			max-width: 72px;
			background: #FFFFFF;
			border: 1px solid #3A76CE;
			margin-right: 28px;
			@include breakpoint(sm){
				margin-right: 15px;
			}
			@include breakpoint(xs){
				max-width:165px;
				min-width: 72px;
				margin-right: 15px;
			}
			span{
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #3A76CE;
				@media (max-width: 359px) {
					font-size: 14px;
				}
			}
		}
		#disabled-next{
			background: #B0BBCB;
			span{
				color: #FFFFFF;
				opacity: 0.35;
			}
		}
		.btn-next{
			width: 165px;
			background: #3A76CE;
			border-color: #3A76CE;
			border-width:1px;
			span{
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #ffffff;	
				@media (max-width: 359px) {
					font-size: 14px;
				}		
			}
			@include breakpoint(sm){
				min-width: 156px;
			}
		}
	}
}	

.answer-container{
	display: block;
	padding: 8px 15px;
	position: relative;
	border-radius: 4px;	
	display: table;	
	border-radius: 4px;	
	border: 1px solid transparent;
	box-shadow: none;	
	transition: all 0.3s ease-in-out;
	cursor: default;	
}
label.answer-container{
	cursor: pointer;
	&:hover,					
	&:focus{
		border-color: #E0E6ED;
		box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	}					
}
.checkmark{
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
 	width: 22px;
    height: 22px;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
    top: 8px;
    left: 13px;
    font-size:10px;
    color:#fff;
    overflow: hidden;
    > svg{  
    	display: block;  	
    	position: absolute;
    	&.checkmark-active{
    		opacity: 0;
    		visibility: hidden;
    	}
    }
}

input.check-answer{
	position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    outline: none !important;
    box-shadow: none;	   
    &:checked{
    	+ .checkmark{				
    		> svg{
		    	&.checkmark-active{
		    		opacity:1;
    				visibility: visible;
		    	}
		    	&.checkmark-basic{
		    		opacity: 0;
    				visibility: hidden;
		    	}
		    }  		
    	}
    }
}
/*.checkmark{
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	background: #ffffff;
    border: 1px solid #8492A5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(59, 72, 87, 0.26);
    -ms-box-shadow: 0px 1px 2px rgba(59, 72, 87, 0.26);
    -webkit-box-shadow: 0px 1px 2px rgba(59, 72, 87, 0.26);
 	width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
    top: 10px;
    left: 15px;
    font-size:10px;
    color:#fff;
}*/

.offered-answer{
	margin-left: 30px;
	font-weight: 600;
	font-size: 16px;
	font-weight: 600;
	font-size: 16px;
	color: #000326;
	line-height:1.4
}
.answer-result-style{
	/*.checkmark{
		background: #FFFFFF;
		border: 1px solid #CDCDCD;
		box-sizing: border-box;
		box-shadow: none;
		-ms-box-shadow: none;
		-webkit-box-shadow: none;
	}*/
	.offered-answer{
		color: #A7A7A7;
	}
}
.my-answer{
	border: 1px solid transparent;
	box-shadow: none;
	-ms-box-shadow: none;
	-webkit-box-shadow: none;
	/*span.checkmark{
		background:#3A76CE;	
		border: 1px solid  #3A76CE;	
		color: #fff;		
	}*/
	.offered-answer{
		color: #000326;
	}
}
.wrong-answer{
	background: #FFFFFF;
	border: 1px solid #CDCDCD;
	box-sizing: border-box;
	box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	/*span.checkmark{
		background: #A7A7A7;
		border: 1px solid  #A7A7A7!important; 
		color: #fff;		
	}*/
	.offered-answer{
		text-decoration-line: line-through;
		color: #A7A7A7!important;
	}
}
.bordered-answer{
	background: #FFFFFF;
	border: 1px solid #CDCDCD;
	box-sizing: border-box;
	box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	.offered-answer{
		color: #A7A7A7!important;
	}
}
.right-answer{
	border: 1px solid #3A76CE;
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	/*span.checkmark{
		background: #3A76CE;	
		border: 1px solid  #3A76CE!important;
		color: #fff;			
	}*/
	.offered-answer{
		color: #000326;
	}
}
.preview-answer{
	background: #FFFFFF;
	border: 1px solid #CDCDCD;
	box-sizing: border-box;
	box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
	/*span.checkmark{
		background: #A7A7A7;
		border: 1px solid  #A7A7A7!important; 
		color: #A7A7A7;		
	}*/
	.offered-answer{
		color: #A7A7A7!important;
	}
}
.answer-explained{
	border-top: 1px solid #D3DCE6;
	padding-top: 20px;
	margin-top: 32px;
	padding-bottom: 80px;
	h4{
		font-weight: 600;
		font-size: 14px;
		text-transform: uppercase;
		color: #000326;
	}
	p{
		margin-bottom: 0px;
		margin-top: 10px;
		padding: 0px;
		font-weight: 600;
		font-size: 14px;
		color: #000326;
	}
}

.targeting-desktop-img{
	margin-bottom: 40px;
	max-width: 300px;
	width: 100%;
	height: 250px;
	display: block;
	@include breakpoint(md){
		display: none;
	}
	img{
		max-width: 100%;
		height: auto;
	}
}
.desktop-show-correct-answer{
	display: block;
	margin-top: 15px;
	max-width: 242px;
	margin-left: auto;
	@include breakpoint(md){
		display: none;
	}
	.counter-title{
		margin-bottom: 16px;
		border-bottom: 1px solid #D3DCE6;
		span{
			font-weight: 600;
			font-size: 14px;
			color: #000326;
		}
	}
}
