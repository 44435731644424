header{  
   	position: fixed;  
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1015;
    background-color: transparent;  
	@include breakpoint(md){ 
	    background-image: url('/images/main-mobile.svg'); 
	    background-repeat:no-repeat;
		-moz-background-size:cover;
		-o-background-size:cover;
		background-size:cover;
		-webkit-background-size:cover;
		background-position:center;
		background-color: #ffffff;
		/*overflow: hidden;*/
		/*&.homepage{
			background-image: url('/images/header-buble-homepage.svg');
		}*/
	}
	.bg-header-fixed{		
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 0px;
		display: flex;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
		&.scrolled{
			height: 60px;
			background-color: #F1F3F8;
			opacity: 1;
			visibility: visible;
		}
		
	}
	.bg-header{
		background-image: url('/images/header-buble.svg'); 
	    background-repeat:no-repeat;
		-moz-background-size:cover;
		-o-background-size:cover;
		background-size:cover;
		-webkit-background-size:cover;
		background-position:center;
		background-color: transparent;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 50px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
		&.scrolled{
			top: 0px;
			opacity: 1;
			visibility: visible;
			height: 100%;
		}
		@include breakpoint(md){
			background-image: none;
		}
	}
	&.homepage{
		.bg-header{
			background-image: url('/images/header-buble-homepage.svg');
			@media (min-width: 991px) and (max-width: 1379px) {
		       background-size: auto;
		    }
			@include breakpoint(md){
				background-image: none;
			}
			@media (min-width: 1679px) {
		       background-position: center 70%;
		    }
		}
	}
	.header-container{
		padding-top: 15px;
		padding-bottom: 3px;	
		@include breakpoint(md){
	    	padding-top: 5px;
			padding-bottom: 0px;	
	    }
	}
   	.ws-collapsible-nav{
     	max-width: 1140px;
	    margin: 0 auto;
	    padding: 10px 15px 20px;
	    font-family: 'Nunito', sans-serif;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    position: relative;	
	    height: 72px;
	    @include breakpoint(md){
	    	height: auto;
	    }
	    .header-collapse-button{
	    	padding: 0px;
	    	outline: none;
	    }
	    .nav-ordered-el{
	    	display: flex;
	    	justify-content: space-between;
	    	align-items: center;
   			width: 80%;
   			@include breakpoint(md){
   				display: none;
   			}
	    }	
		.ws-test-list{
			@include breakpoint(md-lg){
				max-width: 400px;
			}
		    ul.main-nav-ul{
		    	margin: 0;
			    padding: 0;
			    display: flex;
			    justify-content: flex-start;
			    align-items: center;
			    list-style-type: none;
			    min-width: 500px;
			    @include breakpoint(md-lg){
			    	min-width: 400px;
			    }
			    li{
			    	margin-right: 25px;
					a{
						font-weight: 600;
						font-size: 14px;
						color: #000326;	
						padding: 0px;
						transition: all 0.3s ease-in-out;
						&:hover{
							color: #3A76CE;							
						}						
						&:active{
						color: #3A76CE;
						}
					}
					a.dropdown-toggle::after {
						display: none;
					}
			    }
			    li.more-tests{	
				    transition: all 0.3s ease-in-out;	
				    margin-left: 5px;
				    path{
						transition: fill 0.3s;
					}				
					&:hover {
						> a{
						color: #000326;		
							svg{
								path{
									fill: #000326;
								}						
							}			
						}
					}	
					span.space-left{
						margin-left: 3px;						
					}
					span.space-right{
						margin-right: 3px;
					}
					a{
						color: #8492A5;
					}
					ul.more-tests-list{
						text-align: center;
						padding: 0px 0 10px 0;						
						background-color: #ffffff;
						border: 1px solid #E0E6ED;
						box-sizing: border-box;
						box-shadow: 0px 6px 12px rgba(59, 72, 87, 0.22);
						border-radius: 4px;
						li.more-tests-element{
							padding: 0;
							margin-bottom: 0px;
							text-align: left;
							max-width: 440px;
							margin-right: 0px;
							&:hover{
								background-color:#F1F3F8;
							}
							a{
								display: inline-block;
								height: 100%;
								vertical-align: middle;
								font-weight: 600;
								font-size: 12px;
								color: #A7A7A7;
								width: 415px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								padding: 8px 15px 7px;
								span{
									margin-left: 3px;
								}
								&:hover{
									text-decoration: none;
								}
							}
							&.see-all-link{
								margin-top: 10px;
								border: none;
								display: block;
								border: none;
								text-align: center;
								background-color: #ffffff;
								font-weight: 600;
								font-size: 14px;
								color: #3A76CE;
								transition:all 0.3s ease-in-out;								
								a{
									display: flex;
									width: 100%;
									height: 100%;
									justify-content: center;
									align-items: center;
									align-content: center;
									text-align: center;
									font-weight: 600;
									font-size: 14px;
									color: #3A76CE;
									&:hover{
										text-decoration: none;
										background-color: #ffffff;
										color: #184282;
									}
								}
								&:hover{
									background-color: #ffffff;
									color: #184282;
								}
							}
						}
					}
				}
		    }
		}
		.ws-logo{	
			position: absolute;
		    top: -5px;
		    left: 0;
		    right: 0;	
			margin: auto;
			display: block;
			text-align: center;
			width: 100px;
			height: 100%;
			a{
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
				align-content: center;
				position: relative;
			}
		}
		.ws-search-field{	
			display: flex;
			align-items: center;
			justify-content: flex-end;
			#ws-search-form{	
				position: relative;
				text-align: right;
				width: 200px;				
				cursor: pointer;
				border-bottom: 1px solid transparent;
				transition:all ease-in-out 0.15s;	
				input.ws-search-style{
					width: 70px;
					border: none;
					padding: 8px 0px 8px 25px;					
					color: #000326;
					font-weight: 600;
					font-size: 14px;
					background-color: transparent;									
					outline: none;		
					transform-origin: top left;
			  		transition: all .3s ease-in-out;								
					@include placeholder-content{
						color: #000326;
						font-weight: 600;
						font-size: 14px;
					}				
				}
				span#search-icon{
					position: absolute;
					margin-top: 5px;
				}
				.dlt-btn{
					position: absolute;
					right: 0;
					top: 5px;
					display: none;
					background-color: transparent;
				}		
			}	
			#ws-search-form.active 	input{
				width: 100%;
				border-bottom: 1px solid #000326;
			}
		}		
		.ws-client-action{			
			padding: 0;
   			margin: 0;
   			max-width: calc(100% - 40px);
		    .user-actions{
		    	display: flex;
			    justify-content: flex-end;
			    align-items: center;
			    margin-left: 60px;	
			    @media (max-width: 767px) {
			    	 margin-left: 10px;	
			    }		    
		    	.ws-login{
		    		display: block;					
					.login-button{
						border: 1px solid #3A76CE;
						box-sizing: border-box;
						border-radius: 3px;
						background-color: transparent;		
						padding: 9px 16px;
						color: #3A76CE;
						font-weight: 600;
						font-size: 14px;
						text-align: center;
						transition:all 0.3s ease-in-out;
						outline: none;
						&:hover{
							border: 1px solid #184282;
							color: #184282;
						}
					}

				}
				.ws-test-btn{
					margin-left: 20px;
					.test-button{
						text-decoration: none;
						border: 1px solid #3A76CE;
						background-color: #3A76CE;
						box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
						border-radius: 4px;
						padding: 9px 16px;
						color: #ffffff;
						font-weight: 600;
						font-size: 14px;
						text-align: center;
						transition: all 0.3s ease-in-out;
						outline: none;
						&:hover{
							border: 1px solid #184282;
							background-color:#184282;
						}
					}
				}
		    }
		}	
	}
	.logged-user{
		@include breakpoint(md){
	    	display: none;
	    }
	}
	.logged-user-button{
		display: flex;
		align-items: center;
		padding: 6px 16px;
		border: none;
		outline: none;
		background-color: transparent;
		border-radius: 4px;
		transition: all 0.3s ease-in-out;
		border:none;
		text-decoration: none;
		color: #000326;
		&:active,
		&:hover,
		&:focus{
			background-color: #E0E6ED;
		}
		.logged-user-photo{
			position: relative;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			overflow: hidden;
			display: flex;
		    justify-content: center;
		    align-items: center;
		    align-content: center;
			img{
				display: inline;
				margin: 0 auto;
				width: 100%;
				height: 100%;
			}
		}
		.logged-user-name{
			padding: 0px 4px 0px 8px;
			font-weight: 600;
			font-size: 14px;

			color: #000326;
		}
	}
	.logged-user-menu{
		background-color: #FFFFFF;
		box-shadow: 0px 4px 12px rgba(59, 72, 87, 0.3);
		-ms-box-shadow: 0px 4px 12px rgba(59, 72, 87, 0.3);
		-webkit-box-shadow: 0px 4px 12px rgba(59, 72, 87, 0.3);
		border-radius: 4px;
		border: none;
		padding: 16px;
		.logged-user-items{
			padding: 0px;
			margin-bottom: 12px;
			font-weight: 600;
			font-size: 12px;
			color: #000326;
			&:hover{
				background-color: #FFFFFF;
			}
		}
		.log-out-border{
			color: #8492A5;
			margin-bottom: 0px;
			padding-top: 10px;
			border-top: 1px solid #E0E6ED;				
		}
	}
	.profile-menu{
		max-width: 1140px;
	    padding: 0px 15px 20px;
	    margin: 0px auto;
	    display: flex;
	    align-items: baseline;
	    justify-content: space-between;
	    @include breakpoint(md){
	    	flex-direction: column;
	    }
	    .user-tests{
	    	display: flex;
		    justify-content: space-between;
		    align-items: center;
		    width: 100%;
		    @include breakpoint(md){
		    	flex-direction: column;
			    justify-content: flex-start;
			    align-items: flex-start;
		    }
	    }
	    .user-profile-settings{
	    	display: none; 
	    }
	    .tests-title{
	    	font-weight: bold;
			font-size: 24px;
			color: #000326;
			padding-bottom: 8px;
			@include breakpoint(md){
				padding-bottom: 2px;
			}
			h1{
				font-weight: bold;
				font-size: 24px;
				color: #000326;
			}
	    }
	    .tests-mode{	   	
	    	border: none;
    		display: flex;
    		align-items: center;
    		list-style-type: none;
    		margin: 0px;
    		padding: 0px;
    		background-color: transparent;
    		a.test-mode-btn{
    			border: 2px solid transparent;
    			padding-bottom: 8px;
    			margin-left: 40px;
    			font-weight: bold;
				font-size: 16px;
				text-align: right;
				color: #3A76CE;
				background-color: transparent;
				text-decoration: none;	    			
    			&:first-child{
    				margin-left: 0px;
    			}
    			&.active{
    				border-bottom: 2px solid #3A76CE;
    			}
    			@include breakpoint(md){
    				padding-bottom: 8px;
    			}

    		}    		
	    	
	    }
	}
	.profile-line-1{
		    position: absolute;
		    width: 100%;
		    height: auto;
		    top: 0px;
		    left: 0;
		    z-index: -1;
		    height: 100%;
			opacity: 0;
			transition: all 0.8s ease-in-out;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			&.scrolled{
				opacity: 1;
				visibility: visible;
			}
		    svg{
		    	max-width: 100%;
		    }
	}
	.profile-line-2{
		position: absolute;
		width: 100%;
		height: auto;
		top: 0px;
		left: 0;
		z-index: -1;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition: all 0.8s ease-in-out;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		&.scrolled{
			opacity: 1;
			visibility: visible;
		}
		svg{
			max-width: 100%;
		}
	}
	&.homepage{
		.profile-line-1,
		.profile-line-2{
			display: none;		
		}
	}
}





























