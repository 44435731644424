$pagination-color:                     #8492A5 !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    transparent !default;

$pagination-hover-color:               #fff !default;
$pagination-hover-bg:                  #3A76CE !default;
$pagination-hover-border:              transparent !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 #3A76CE !default;
$pagination-active-border:             transparent !default;

$pagination-disabled-color:            #3A76CE !default;
$pagination-disabled-bg:               #D5E6FF !default;
$pagination-disabled-border:           transparent !default;

@mixin placeholder-content{
  &::-webkit-input-placeholder {
      @content;
  }
  &:-moz-placeholder {
      @content;
  }
  &::-moz-placeholder {
      @content;
  }
  &:-ms-input-placeholder { 
      @content;
  }
}

@mixin breakpoint($point) {
  @if $point == xs {
    @media only screen and (max-width: 575px)  { @content; }
  }
   @if $point == sm {
    @media only screen and (max-width: 767px)  { @content; }
  }
  @if $point == md {
    @media only screen and (max-width: 991px) { @content; }
  } 
  @if $point == lg {
      @media only screen and (max-width: 1200px) { @content; }
  }
   @if $point == min-md {
      @media only screen and (min-width: 992px) { @content; }
  }
   @if $point == md-lg {
    @media only screen and (min-width: 992px) and (max-width:1200px) { @content; }
  }
  @if $point == sm-md {
    @media only screen and (min-width: 768px) and (max-width:990px) { @content; }
  }
}

@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $size $color;
        -moz-box-shadow:inset $top $left $blur $size $color;
        box-shadow:inset $top $left $blur $size $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $size $color;
        -moz-box-shadow: $top $left $blur $size $color;
        box-shadow: $top $left $blur $size $color;
    }
}

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 6px;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
    width: 7px;
    margin-right: auto;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}


