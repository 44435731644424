abbr[title], abbr[data-original-title]{
	text-decoration:none;
}
.privacy-content{
	.mdl-cell{
		padding-top: 20px;				
		padding-bottom: 25px;
		border-bottom: 1px solid #E5EAF5;			
		h1{
			font-weight: 600;
			font-size: 40px;
			margin-top: 45px;
			color: #000326;
			@include breakpoint(md){
				font-size: 34px;
			}
			@include breakpoint(sm-md){
				text-align: center;
			}	   
		}
		h2{
			font-weight: 600;
			font-size: 26px;
			color: #000326;
			margin-top: 45px;
			@include breakpoint(md){
				font-size: 22px;
			}   
		}
		h3{
			font-weight: 600;
			font-size: 24px;
			color: #000326;
			margin-top: 40px;
			@include breakpoint(md){
				font-size: 18px;
			}   
		}
		mark{
			font-weight: 600;
		    font-size: 16px;
		    color: #8492A5;
		}
		p{
			font-weight: 600;
		    font-size: 16px;
		    color: #8492A5;
		    margin-top: 20px;
		    margin-bottom: 0px;
		}
		address{
			font-weight: 600;
		    font-size: 16px;
		    color: #8492A5;
		    margin-top: 20px;
		    margin-bottom: 0px;
		    span{
		    	display: block;
		    }
		}
		ul{
			font-weight: 600;
		    font-size: 16px;
		    color: #8492A5;
		    margin-top: 20px;
		    margin-bottom: 0px;
			li{
				font-weight: 600;
		    	font-size: 16px;
		    	color: #8492A5;
			}
		}
		a{
			color: #3A76CE;
			&.hover,
			&.focus{
				color: #8492A5
			}
		}
	}
}


#question-answer{
	position: relative;	
	padding: 5px 0px;
	width: 100%;
	display: block;
}

a{
	text-decoration: none;
	&:hover,
	&:focus{
		text-decoration: none;
	}
}
.box {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-size: 30px;
  padding: 1em;
  position: relative;
  margin-bottom: .25em;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: .3s color, .3s border, .3s transform, .3s opacity;
}
.loader-box {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0px;
  top: 0px;  
  &.showed{
  		z-index: 99;
  		opacity: 1;
  		visibility: visible;
  }
}

.loader-10 {
  width: 70px;
  height: 70px; 
  position: relative;
  animation: loader-10-1 2.0s infinite linear;
  &:before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    border: .5em solid #3A76CE;
    display: block;
    position: absolute;
    border-radius: 100%;
    animation: loader-10-2 2s infinite ease-in-out;
  }
  &:before {
    top: 0;
    left: 50%;
  }
  &:after {
    bottom: 0;
    right: 50%;
    animation-delay: -1s;
  }
}

@keyframes loader-10-1 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-10-2 {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}


#main-content{
	min-height: 100vh;
}

.owl-carousel{
	display: block;
	position: relative;
	@include breakpoint(md){
		padding-bottom: 50px;
	}
	.owl-nav{
		position: absolute;
	    bottom: -12px;
	    display: flex;
	    width: 100%;
	    padding: 0 15px;
	    justify-content: space-between;
	    font-size: 44px;
	    color: #8492A5;
	}
}

h1, h2, h3, h4, h5 {
	margin-bottom: 0px;
	font-weight: normal;
}
mark{
	padding: 0px;
    background-color: transparent;
    color: #000326;
}
.all-page-container{
	max-width: 1140px;
	padding: 100px 15px 60px;
	margin: 0 auto;
	position: relative;
	@include breakpoint(md){
		padding-top: 100px;
		padding-bottom: 20px;
	}
}

.sidebar-ad{
	display: block;
	width: 100%;
	@include breakpoint(sm){
		max-height: 250px;
	}
}

.bottombar-ad{
	display: block;
	width: 100%;
	max-height: 250px;
	@include breakpoint(md){
		max-height: 720px;
	}
}

.first-title{
	padding:40px 0px 12px 0px;
	@include breakpoint(sm){
		padding:10px 0px 12px 0px;
	}
	.back-to-tests-btn{
		padding: 0px;
		display: flex;
		align-items: center;
		border: none;
		outline: none;
		background-color: #ffffff;	
		color: #8492A5;
		font-weight: bold;
		font-size: 14px;
		.arrow-to-right{
			width: 16px;
		    height: 13px;
		    background-image: url('/images/arrow-right-btn.svg');
		    background-repeat: no-repeat;
		    background-position: left;		
		}
	}
	.current-test-title{
	   	padding-bottom: 12px;
	   	width: 100%;
	   	border-bottom: 1px solid #D3DCE6;
	   	display: flex;
	   	align-items: center;
	   	justify-content: space-between;
	   	@include breakpoint(md){
			flex-direction: column;
			padding-bottom: 8px;
		}
	   	.chosen-test-title{
	   		h4{
	   			font-weight: bold;
				font-size: 14px;
				color: #000326;
	   		}
	   	}
	   	.training-exam-mode{
	   		font-weight: 600;
			font-size: 14px;
			text-align: right;
			color: #8492A5;
	   	}
	   	.test-unique-title{
			max-width: 782px;
			margin-right: auto;
			text-align: left;
			h4{
				font-weight: bold;
				font-size: 14px;
				color: #000326;
			}
			@include breakpoint(md){
				max-width: 100%;
				padding-bottom: 15px;
			}
		}
		.dif-des-mob{
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include breakpoint(md){
				width: 100%;
			}
			.current-test-mode{
				mark{
					font-weight: 600;
					font-size: 14px;
					text-align: right;
					color: #8492A5;
				}
			}						
		}
	}
	.all-tests-page-title{
		font-weight: bold;
		font-size: 24px;
		color: #000326;
		text-align: left;
	}		
}

 path{
	transition: fill 0.3s;
 }
 .main-wrapper{
	display: flex;
	align-items:flex-start;
	justify-content: space-between;
	&.streched{
		align-items: stretch;
	}
	@include breakpoint(md){	
		flex-direction: column;
	}
}
.sidebar-bunner{
	max-width: 100%;
	width: 300px;
	margin-left: auto;
	position:relative;
	@include breakpoint(md){
		width: 100%;			
		margin: 0px;
		order:1;
	}
}



#notification_content {position: fixed;bottom: 45px;right: 10px;width: 340px;z-index: 201;color: #fff;}
#notification_content .notification {font-size: 13px;transition: all 0.6s ease-in-out;background:#3A76CE;color: #fff;opacity: 1;visibility: visible;padding: 10px 35px 10px 10px;margin-bottom: 5px;position: relative;box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);-ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);}
#notification_content .notification p {font-size: 13px;color: #fff;}
#notification_content .notification a {color:#fff;text-decoration: none;display: flex;justify-content: center;align-items: center; width: 30px; position: absolute;right: 10px;top: 0px;bottom: 0px;margin: auto;}
#notification_content .notification a.close_toast {font-size: 13px;position: absolute;right: 5px;display: block;width: 22px;height: 22px;top: 50%;margin-top: -11px;color: #fff;text-align: center;}
#notification_content .notification.slide_notification {opacity: 0;visibility: hidden;}
#notification_content .notification.hide_notification {display: none;}
#notification_content .notification.success {background: #3A76CE}
#notification_content .notification.error {background: #EC6161}