.profile-content{
	padding-top: 48px;
	@include breakpoint(md){
		padding-bottom: 15px;
	}
	.no-tests-taken{
		padding-bottom: 50px;
		@include breakpoint(md){
			padding-top: 50px;
		}
		.no-tests-shown{
			padding: 55px 0px;
			text-align: center;
			.no-tests-img{
				width: 120px;
				height: 114px;
				margin: auto;
				img{
					max-width: 100%;
					height: auto;
				}
			}
			.no-tests-text{
				margin-top: 16px;
				span{
					font-weight: 600;
					font-size: 20px;
					text-align: center;
					color: #B0BBCB;
				}
			}
		}
	}
}
.no-tests-page{
	@include breakpoint(md){
		padding-bottom: 0px;
	}
}
.profile-training-tests{
	.tests-options-btn{
		padding: 60px 0px 35px 0px;
		text-align: center;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		.option-button{
			border: none;
			text-align: center;
			padding: 8px 16px;
			border-radius: 4px; 
			outline: none;
			width: 130px;
			height: 40px;
			background-color: #F1F3F8;
			border: 2px solid #F1F3F8;
			font-weight: 600;
			font-size: 14px;
			text-align: center;
			color: #8492A5;
			margin: 0 15px;
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
			&.active{
				background-color: #ffffff;
				border-color: #3A76CE;
				color: #3A76CE;
			}
			@include breakpoint(md){
				margin-left: 0px!important;
			    margin-right: 0px!important;
			    width: 100%;
			    margin-top: 16px;
			}
			span.option-btn-icon{
				margin-right: 5px;
				display: flex;
			    height: 100%;
			    align-items: center;
			    align-content: center;
			    justify-content: center;
			}
		}
	}
	.display-chosen-sequence{
		padding-bottom: 80px;
	}
}
.profile-exam-tests{
	.display-chosen-sequence{
		padding-top: 40px;
		padding-bottom: 75px;
	}	
}
.completed-date{
	position: absolute;
    top: -9px;
    left: 40px;
	background: #FFFFFF;
	border: 1px solid #C9D3DE;
	box-sizing: border-box;
	border-radius: 33px;
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: #8492A5;
	padding:2px 4px 2px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}
span.completed-icon, span.in-process-icon{
	position: absolute;
   	top: -9px;
    left: 15px;
	background: #FFFFFF;
	border: 1px solid #C9D3DE;
	border-radius: 100%;
	color: #8492A5;
	padding:2px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;

}
span.passing-icon{
	position: absolute;
    top: -9px;
    right: 20px;
    display: flex;
    border-radius: 3px;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 1;
	padding:3px 5px 3px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	color: #fff;
	i{
		margin-right: 5px;
		line-height: 1;
		display: inline-block;
		vertical-align: middle;
	}
    &.not-passed-icon{
    	background-color: #ec6161;
    }
    &.passed-icon{
    	background-color: #16BD81;
    }

}

.training-tests-page{
	padding-bottom: 50px;
}
.training-test-result{	
	@include breakpoint(md){
		padding-bottom: 15px;
	}	
	.training-results-place{
		position: relative;
		display: flex;
		justify-content: space-between;
		@include breakpoint(md){
			flex-direction: column;
		}
		.result-questions{
			max-width: 692px;
			margin-right: auto;
			margin-bottom: 80px;
			.answer-options{
				width: 100%;
				text-align: left;
				border-bottom: 0px;
				display: flex;
				flex-wrap: nowrap;
				@include breakpoint(md){
					border: none;
				}
				li.nav-item{				
					min-width: 215px;
					margin: 0px;
					display: inline-block;
					@include breakpoint(md){
						min-width: auto;
					}
					a{
						padding: 8px 16px;
						background: #F1F3F8;
						border: 1px solid #C9D3DE;			    
					    border-radius: 0px;
						font-weight: bold;
						font-size: 14px;
						color: #8492A5;
						text-align: center;					
					}
					a.active{
						background-color: #FFFFFF;
						font-weight: bold;
						font-size: 14px;
						color: #3A76CE;
					}
					a#answered-q{
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;					
					}
					a#unanswered-q{
						border-left: transparent;
						border-top-right-radius: 4px;
						@include breakpoint(md){
							border-bottom-right-radius: 4px;
						}
					}
				}
				.only-mask{
					width: 90%;
				    border-bottom: 1px solid #C9D3DE;
				    margin-left: auto;
				    display: block; 	
				    @include breakpoint(md){
				    	display: none;
				    }
				}
				.show-question-result{
					padding-top: 32px;
					padding-bottom: 80px;
				}
			}
			.answered-question{
				padding-top: 32px;
				.answered-questions-wrapper{
					width: 100%;
					height: 100%;
					margin: 0;
					padding: 0;
					list-style-type: none;
					.answered-question-content{
						position: relative;
						border: 1px solid transparent;
						transition: all 0.3s ease-in-out;
						padding: 8px 15px;
						margin-bottom: 10px;
						span.arrow-down{
							position: absolute;
							top: 5px;
							right: 15px;
							transition: all 0.3s ease-in-out;
							margin-left: 15px;
							display: none;
						}										
						&:hover{							
							border-color: #E0E6ED;
							background: #FFFFFF;							
							box-sizing: border-box;
							box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
							-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
							-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
							border-radius: 4px;							
							span.arrow-down{
								display: inline;
							}					
						}
						&.opened{
							border-color: #3A76CE;
							background: #FFFFFF;							
							box-sizing: border-box;
							box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
							-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
							-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
							border-radius: 4px;							
							span.arrow-down{
								display: inline;
								transform: rotate(180deg);
								-ms-transform: rotate(180deg);
								-webkit-transform: rotate(180deg);
								svg{
									path{
										fill: #000326;
									}
								}
							}					
							.main-question{
								span.question{color: #000326;}
							}
						}
						.forward-buttons{
							display: none;
						}
						@include breakpoint(md){
							border: none;
							box-shadow: none;
							border-radius: 0px;
						}
						.main-question{	
							text-decoration: none;
							font-weight: bold;
							span.question{								
								font-size: 16px;
								color: #8492A5;
							}
							span.q-number{
								font-size: 16px;
								color: #8492A5;
								margin-right: 3px;	 
							}
							span.q-number-icon{
								margin-right: 3px; 
								display: none;
								@include breakpoint(md){
									display: inline;
								}
							}							
						}
						.answer-question-content{
							padding-top: 23px;
							padding-bottom: 10px;											
						}
					}
				}
			}
			#unanswered{
				.arrow-down{
					display: none;
				}
			}
		}
		.go-up-place{
			position: absolute;
			bottom: 0px;
			right: 0px;
			@include breakpoint(md){
				margin-top: 44px;
    			position: relative;
			}
			.go-up-arrow{
				text-align: center;
				width: 60px;
				height: 60px;
				border: 1px solid #E0E6ED;
				border-radius: 50%;
				box-sizing: border-box;
				box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
				-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
				-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
				@include breakpoint(md){
					margin: auto;
				}
				.go-up-text{
					font-weight: 600;
					font-size: 12px;
					text-align: center;
					color: #8492A5;
				}
				.go-up-icon{
					transform: scale(0.6);
					-ms-transform: scale(0.6);
					-webkit-transform: scale(0.6);
				    vertical-align: bottom;
				    margin-top: 5px;
				}
			}
		}
	}
}
.exam-test-result{	
	.exam-needed-percents{
		position: absolute;
	    width: 0%;
	    height: 4px;
	    background-color: #8492A5;
	    border-radius: 21px;
	    z-index: 2;
	    top: 0;
	    left: 0;
	    .need-to-pass-text{
	    	color: #8492A5;
	    	padding-top: 10px;
		    padding-right: 12px;
		    border-right: 2px solid #8492A5;
	    }
	}
	.passed-exam-score{
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 0%;
	    height: 4px;
	    background:  #EC6161;
	    border-radius: 21px;
	    z-index: 3;
	    &.passed{
	    	background:  #16BD81;
	    	.user-exam-score{
	    		border-color: #16BD81;
		    	color:  #16BD81;
		    	&.too-low-score{
					border-color: #16BD81;
		    		color:  #16BD81;
				}

	    	}
	    }
	    .user-exam-score{
	    	position: absolute;
		    right: 0;
		    top: 0px;
		    padding-top: 70px;
		    padding-bottom: 5px;
		    padding-right: 12px;
		    text-align: right;
		    border-right: 2px solid #EC6161;
		    color:  #EC6161;
		    font-weight: 600;
			font-size: 12px;
			white-space: nowrap;
			&.too-low-score{
				border-right-width: 0px !important;
				border-left: 2px solid #EC6161;
				padding-right: 0px !important; 
				padding-left: 12px !important; 
				text-align: left !important;
				right: -70px !important;
			}
	    }
	}
	.exam-results-wrapper{
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 0px;
		@include breakpoint(md){
			margin-bottom: 0px;
			flex-direction: column;
		}
		.exam-questions-results{
			max-width: 100%;
			margin-right: auto;
			margin-bottom: 10px;
			width:100%;
			padding-right:30px;
			&.full-width{
				padding-right:0px;
			}
			@include breakpoint(sm){
				max-width: 100%;
			   	margin-bottom: 0px;
			   	padding-right:0px;
			}			
		}
		#try-exam-tests{	
			width: 300px;
			margin-left: auto;
			@include breakpoint(md){
				margin-left: 0px;
				margin-top: 70px;
				padding-bottom: 40px;
				width: 100%;
			}
			.test-container{
				flex-direction: column;
				.test-lasting{
					width: 100%;
					display: flex;
					justify-content: flex-start;
					.num-of-question{
						margin-right: 20px;
					}
				}
			}
			mark{
				font-weight: bold;
				font-size: 16px;
			}
			/*.locked-test{
				padding: 16px;
				flex-direction: column;
				.test-title{
					span{
						color: #FFFFFF;
					}
				}
				.test-lasting{
					width: 100%;
				    display: flex;
				    justify-content: flex-start;
				    .num-of-question{
				    	margin-right: 20px;
				    }
				}
			}	*/
		}
		.targeting-mobile-img{
			display: none;
			margin: 30px auto;
			@include breakpoint(sm){
				display: block;
			}
			img{
				width: 100%;
				height: auto;
			}
		}
	}			
}
.profile-settings{
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	@include breakpoint(md){
		flex-direction: column;
	}
	.profile-edits{
		display: flex;
		width: 100%;
		text-decoration: none;
		&.active{
			display: none;
		}
	}
	.user-personal-data{
		position: relative;		
		display: flex;
		align-items: baseline;
		@include breakpoint(md){
			flex-direction: column;
			max-width: 100%;
			width: 100%;
			padding: 32px 0px;
			border-bottom: 1px solid  #E0E6ED;
		}
		.personal-data-holder{
			display: flex;
   			align-items: center;
   			@include breakpoint(md){
   				justify-content: space-between;
				width: 100%;
			}   			
   			.user-image{
   				position: relative;
   				overflow: hidden;
   				width: 64px;
   				height: 64px;
   				border-radius: 50%;
   				background-color: #B0BBCB;
   				margin-right: 16px;
   				
   				&:hover{
   					.change-img{
   						opacity:0.8;
   						visibility: visible;   						
   					}
   				}
   				img{
   					display: inline;
				    margin: 0 auto;
				    width: 100%;
				    height: 100%;
   				}   					
   			}
   			.change-img{
   				transition: all 0.3s ease-in-out;
   				visibility: hidden;
   				cursor: pointer;
   				position: absolute;
   				border: 2px solid transparent;
   				background-color: #FFFFFF;
   				opacity: 0;
   				background-image: url('/images/change-photo.svg');
   				background-position: center;
   				background-repeat: no-repeat;
   				width: 56px;
   				height: 56px;
   				top: 0px;
				left: 0px;
				right: 0;
				bottom: 0;
				border-radius: 50%;
				margin: auto;   					
   			}
   			.user-data-part{
   				display: flex;
   				align-items: center; 
   				@include breakpoint(md){
   					justify-content: space-between;
    				width: 100%;
   				}  				
   				.full-name{
   					font-weight: 600;
					font-size: 20px;
					color: #000326;
   				} 
   			}
		}		
	}
	.user-account-data{
		max-width: 500px;
		margin-left: auto; 
		@include breakpoint(md){
			max-width: 100%;
			width: 100%;
		}
		.user-email-field{
			display: flex;
    		justify-content: space-between;
			width: 100%;      
			padding: 60px 0px 32px 0px;	
			@include breakpoint(md){
				flex-direction: column;
				padding: 32px 0px;
				border-bottom: 1px solid  #E0E6ED;
			}
			.user-email{
				position: relative;	
				.user-email-text{
					font-weight: 600;
					font-size: 18px;
					color: #000326;
					@include breakpoint(md){
						font-size: 16px;
					}
				}	
			}
		}	
		.user-password-field{	
			   /* display: flex;
  				justify-content: space-between;*/
			@include breakpoint(md){
				/*flex-direction: column;*/
				padding: 32px 0px;
				border-bottom: 1px solid  #E0E6ED;
			}
		}
		.column-on-mob{
			display: flex;
			align-items: baseline;
			@include breakpoint(md){
				flex-direction: column;
			}				
		}
		.account-data-text{					
			font-weight: 600;
			font-size: 18px;
			color: #8492A5;
			min-width: 167px;
		    margin-right: auto;
		    text-align: left;
			@include breakpoint(md){
				margin-bottom: 10px;
				font-size: 16px;
			}
		}			
		.pass-dots{
			display: flex;
				 align-items: center;
				 color: #000326;
			.circle{
				width: 5px;
				height: 5px;
				margin-right: 5px;
			}
		}
		.user-email, .user-password{
			display: flex;
			align-items: baseline;
			position: relative;
			z-index: 1;
			@include breakpoint(md){
				justify-content: space-between;			
			}			
			.account-data-text{					
				font-weight: 600;
				font-size: 18px;
				color: #8492A5;
				min-width: 167px;
			    margin-right: auto;
			    text-align: left;
				@include breakpoint(md){
					margin-bottom: 10px;
					font-size: 16px;
				}
			}			
			.pass-dots{
				display: flex;
   				 align-items: center;
   				 color: #000326;
				.circle{
					width: 5px;
					height: 5px;
					margin-right: 5px;
				}
			}
		}
	}
	.middle-element{
		@include breakpoint(sm){
			position: absolute;
	   		top: 32px;
	   		left: 3px;
	   	}
	}
	.edit{
		cursor: pointer;
		margin-left: 24px;		
		.edit-text{
   			font-weight: 600;
			font-size: 14px;
			color: #3A76CE;
   		}
	}
	.open-to-edit{
		position: relative;
		z-index: 5;
		max-width: 325px;
    	min-width: 323px;
		margin-left: auto;
		@include breakpoint(md){
			margin-top: -5px;
			max-width: 100%;
			width: 100%;
		}
		@include breakpoint(sm){
			min-width: 200px;
			width: 100%;
		}
		.styled-fields{
			width: 100%;
			outline: none;
			margin-bottom: 15px;
			background: #FFFFFF;
			border: 1px solid #C9D3DE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			padding: 11px 16px 9px 16px;
			font-weight: 600;
		    font-size: 18px;
		    color: #000326;
			@include placeholder-content{				
				font-weight: 600;
				font-size: 18px;
				color: #B0BBCB;
			}
		}		
	}
	.account-buttons{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.account-cancel-btn{
			background: #FFFFFF;		
			color: #3A76CE;
		}
		.account-save-btn{
			background: #3A76CE;
			color: #FFFFFF;			
		}
		.account-cancel-btn, .account-save-btn{
			width: 48%;
			outline: none;
			border: 1px solid #3A76CE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-ms-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			-webkit-box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			padding: 11px 16px 9px 16px;
			text-align: center;
			font-weight: 600;
			font-size: 18px;
			text-align: center;
		}
	}
}
.questions-results-num{	
	padding: 20px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #D3DCE6;	
	border-bottom: 1px solid #D3DCE6;
	max-width: 100%;
	@include breakpoint(md){
		flex-direction: column;
	}
	.question-result-type{
		margin-right: 45px;
		font-weight: bold;
		font-size: 12px;
		color: #000326;
		@include breakpoint(md){
			margin-right: 0px;
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0px;
			}
		}
		.question-result-style{
			font-size: 20px;
			margin-right: 2px;
		}
	}				
}
.questions-results-table{
	margin-top: 24px;
	.results-table-title{
		mark{
			font-weight: 600;
			font-size: 14px;
			color: #8492A5;
		}
	}
	.results-table{
		padding: 12px 0px 36px 0px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
   		align-items: center;
   		width: 100%;
   		height: 100%;
		.question-unique-number{
			width: 48px;
			height: 48px;
			text-align: center;
			border-radius: 4px;
			margin: 0 7px 7px 0px;
			padding:0px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			border: 1px solid transparent;
			@include breakpoint(sm){
				width: 36px;
				height: 36px;
			}
			> a{
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				position: relative;
				width: 100%;
				height: 100%;
				text-decoration: none;
				transition: all 0.2s ease-in-out;
				border-radius: 4px;
				&:hover,
				&:focus{
					color:#3A76CE;
    				background-color: #D5E6FF;
				}
				.question-details{
					display:none;
				}
			}
			span.q-number{
				font-weight: 600;
				font-size: 14px;
				text-align: center;
				vertical-align: middle;
			}
			span.flag-icon{
				position: absolute;
				bottom: 0px;
				right: 1px;
			}
			&.passed-answer,
			&.correct-answer{
				background-color: #E0E6ED;
				border-color: #E0E6ED;
				.q-number{
					color: #3A76CE;
				}
			}
			&.incorrect-answer{
				background: #FFE3E3;
				border-color: #EC6161;
				.q-number{
					color: #EC6161;
				}
			}
			&.skipped-question,
			&.skipped-answer,
			&.unanswered-answer,
			&.unanswered-question{
				background: #3A76CE;
				border-color: #3A76CE;
				.q-number{
					color: #ffffff;
				}

			}	
			&.current-question-place{
				border-color: #3A76CE;
				background-color: #ffffff;
				.q-number{
					color: #3A76CE;
				}
			}
		}
	}

}
.question-result-template{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-content: center;
	.ordered-on-mobile{
    	display: flex;
    	@include breakpoint(sm){
    		flex-direction: row;
    		flex-wrap: wrap;
    	}
    	.results-pattern{
    		margin-bottom: 20px;
    	}
    }
	.results-pattern{
		margin-right: 35px;
		display: flex;	
		align-items: center;	
		@include breakpoint(sm){
    		margin-right: 10px;
    		margin-top:15px;
    	}			
		.pattern-style{			
			border-radius: 4px;
			margin-right: 7px;
			width: 28px;
			height: 28px;
			display: flex;	
			align-items: center;
			border: 1px solid transparent;
			.total-result{
				font-weight: 600;
				font-size: 12px;
				line-height:2;
				text-align: center;
				width: 28px;
				height: 28px;
				text-align: center;				
				margin: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				position: relative;

			}
			&.passed-answer,
			&.correct-answer{
				background-color: #E0E6ED;
				border-color: #E0E6ED;
				.q-number{
					color: #3A76CE;
				}
			}
			&.incorrect-answer{
				background: #FFE3E3;
				border-color: #EC6161;
				.q-number{
					color: #EC6161;
				}
			}
			&.unanswered-question{
				background: #3A76CE;
				border-color: #3A76CE;
				.q-number{
					color: #ffffff;
				}
			}	
			&.current-question-place{
				border-color: #3A76CE;
				background-color: #ffffff;
				.q-number{
					color: #3A76CE;
				}
			}
		}
		.result-type-text{
			max-width:67px;
			font-weight: 600;
			font-size: 12px;
			color: #8492A5;
			line-height: 1.2;
		}
	}
}