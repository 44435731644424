#searchDropdownMenuHolder{
	padding-left: 12px;
	position: relative;
	.form-inline{
		flex-wrap: nowrap;
		position: relative;
		input{
			outline:none;
			box-shadow: none;
			min-height: 42px;
			&:hover,
			&:focus{
				outline:none;
				box-shadow: none;
			}
		}
		.btn{
			position: absolute;
			right: 0px;
			top: 0px;
			color: #3A76CE;

		}
	}

}


#searchDropdownResults{
	position: absolute;
    z-index: 9;
    margin: 0 auto;
    text-align: left;
    background: #FFFFFF;
    border-radius: 4px;
   	box-shadow: 0px 6px 13px rgba(59, 72, 87, 0.26);	
   	width: 100%;
   	overflow: hidden;
   	min-width: 320px;
   	right: 0px;
    .wsaListShow{
    	position: relative;
    	border-radius: 4px;
    	padding-top: 10px;
	    padding-bottom: 10px;
	    padding-left: 16px;
	    padding-right: 16px;
	    border: 1px solid rgba(59, 72, 87, 0.26);
	    width: 100%;
	    overflow: hidden;
	    min-width: 300px;
	    .wsaListShowOverflow{
	    	max-width: 360px;
		    min-width: 300px;
		    width: 100%;
		    height: 320px;					    
		    overflow: auto;
		    width: calc(100% + 45px);
		    &.no-results{
		    	height: auto;
		    	width: calc(100% - 15px);
		    	min-width: auto;
		    }
		    .zip-list-title{
		    	font-weight: 500;
			    font-size: 16px;
			    color: #3A76CE;
			    border-bottom: 1px solid #8492A5;
			    margin-bottom: 10px;
			    label{
			    	margin: 0px;
			    	color: #8492A5;
			    		
			    }
		    }
		    ul{
		    	list-style: none;
		    	display: flex;
		    	flex-direction: column;
		    	width: 320px;
		    	position: relative;
		    	justify-content: space-around;
		    	margin: 0px;
		    	padding: 0px 0 10px 0;
		    	li{
		    		display: block;
		    		margin-bottom: 16px;
		    		padding-right: 30px;
		    		line-height: 1;
		    		a{
		    			color:#000326;
						font-size: 14px;
						text-decoration: none;	
						line-height: 1;		
						white-space: initial !important;	
						i{
							margin-right: 3px;
						}
						.highlighted{
							color:#3A76CE;
						}
						&:hover,
						&:focus{
							color:#3A76CE;
						}
		    		}
		    	}

		    }
	    }
	    .cta-btn{
	   		position: absolute;
	   		width: 100%;
	   		left: 0px;
	   		bottom: 0px;
	   		max-height: 30px;
	   		a.btn{
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-content: center;
				background: #3A76CE;
				border-radius: 4px;
				height: 32px;
				outline: none;
				max-width: 320px;
				border:2px solid #3A76CE;
				padding: 0px;
				-webkit-transition: all ease-out 0.4s;
				-moz-transition: all ease-out 0.4s;
				transition: all ease-out 0.4s;
				white-space: nowrap;
				label{
					width: 100%;
					text-align: left;
					padding-left: 24px;
					transition: all 0.4s ease;
				}
				span{
					display: flex;
					height: 100%;
					width: 66px;
					text-align: center;
					justify-content: center;
					align-content: center;
					align-items: center;
					background-color: #3A76CE;
					border-radius: 0 4px 4px 0;
					-webkit-transition: all ease-out 0.6s;
					-moz-transition: all ease-out 0.6s;
					transition: all ease-out 0.6s;
					position: relative;
					&:before{
						content: "";
						position:absolute;
						top: 0px;
						left: 0px;
						bottom: 0px;
						width: 1px;
						height: 36px;
						margin: auto;
						background-color: rgba(255,255,255,0.2);
					}
				}
			}
	   	}
    }
}

#search-results{
	.first-title{
		padding: 0px 0px 0px 0px;
		.current-test-title{
			min-height: 58px;
			padding: 0px 0px 0px 0px;
		}
	} 
	.second-title{
		padding-bottom: 16px;
	}
	.question-part{
		.test-current-question{
			margin-bottom: 16px;
			h3{
				font-weight: bold;
			    font-size: 14px;
			    color: #000326;
			}
		}
		.answer-result{
			margin-bottom: 16px;
			.answer-container{
				display: none;
				margin-bottom: 0px;
				&.right-answer{
					display: flex;
					position: relative;					
				}
				> mark.key-identifier{
					display: none;
				}
				.offered-answer{
					font-size: 14px;
				}
			}
		} 
	} 
	.answer-explained{
		padding-bottom: 0px;
		margin-top: 0px;
		padding-top: 12px;
		border: none;
		p.explanation{
			position: relative;
			display: block;
			padding-left: 7px;
			&:after{
				content: "";
				position:absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;
				backdrop-filter: blur(2px);
				background: rgba(255, 255, 255, 0.2);
				z-index: 2;
				left: 0px;
				top: 0px;
			}
		}
	}
}


.pagination-holder{
	margin: 16px auto;
	display: block;
	width: 100%;
	nav{
		position: relative;
		display: flex;
		overflow: hidden;
		width: 100%;
		justify-content: center;
		.pagination{
			justify-content: center;
		}
	}
}