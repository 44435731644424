input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    box-shadow:none;
}


.overlay-processing{
	width: 100%;
	height: 100vh;
	background-color: rgba(194, 198, 209, 0.95);
	z-index: -1;
	opacity: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	box-shadow: 0px 4px 20px rgba(59, 72, 87, 0.04);
	backdrop-filter: blur(16px);
	background: rgba(255, 255, 255, 0.9);
	.overlay-processing-container{
		max-width: 1280px;
		margin:auto auto;
		width:100%;
		text-align: center;
		div{
			color: #01508C;
			margin: 0 auto;
			width: 240px;
			text-align: center;
			position: relative;
		}
	}
}

/*.braintree-placeholder{
	margin: 0px !important;
	display: none !important;
}
*/

.cart-top-description{
	display: block;
	margin: 28px auto 56px;
	width: 100%;
	position: relative;
	@include breakpoint(sm){
		display: none;
	}
	.cart-title{
		h1{
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			color: #000326;			  
		}
	}
}

#order-details-scroller{
	position: relative;
	display: block;
	width: 100%;
}

#order-details-holder{
	width: 100%;
	position: relative;
	margin-bottom: 10px;
	-webkit-transition: position ease-out 0.4s;
	-moz-transition: position ease-out 0.4s;
	transition: position ease-out 0.4s;	
	&.sticky-full-js{		
    	position: sticky;
    	top: 100px;
    	@include breakpoint(sm){
			padding: 0px;
		}    	
			    	
    }	
    .order-title{
		display: block;
		margin: 28px auto 20px;
		width: 100%;
		position: relative;
		padding-bottom: 12px;
		border-bottom: 2px solid #000326;
		h2{
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			color: #000326;
			padding-left: 4px;
		}
	}
	.order-details{
		display: block;
		margin: 0px auto 20px;
		width: 100%;
		position: relative;
		padding-bottom: 12px;
		border-bottom: 2px solid #8492A5;
		.product-type{
			display: block;
			margin-bottom: 5px;
			h4{
				font-weight: bold;
			    font-size: 16px;
			    text-align: left;
			    color: #000326;
			    padding-left: 4px;
			}
		}
		.products-included{
			margin-bottom:2px;
			ul{
				padding: 0 0 0 8px;
				margin: 0;
				width: 100%;
				display: flex;
				flex-direction: column;
			}
			.product-row{
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				align-content: center;
				position: relative;
				padding-left: 4px;
				.product-title{
					font-weight: 600;
					font-size: 14px;				
					color: #000326;
					margin: 0px;
					display: flex;
					align-content: center;
					align-items: center;
					position: relative;
					span{
						margin-right: 12px;
						display: flex;
						justify-content: center;
						align-items: center;
						align-content: center;

					}
				}
				.product-price{
					font-weight: 600;
					font-size: 14px;				
					color: #000326;
					text-align: right;
					padding-right: 4px;
				}
			}
		}
	}
	.total-details{
		display: block;
		margin: 0px auto 20px;
		width: 100%;
		position: relative;
		padding-bottom: 12px;
		.total-row{
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			position: relative;			
			.total-title{
				font-weight: 700;
				font-size: 16px;
				padding-left: 4px;
				color: #8492A5;
			}
			.total-price{
				font-weight: 700;
				font-size: 16px;
				padding-right: 4px;
				color: #8492A5;
				text-align: right;
			}
		}
	}	
}	

#cart-acount-details{
	display: block;
	width: 100%;
	max-width: 540px;
	margin: 0 auto;
	position: relative;
	.confirm-payment{
		display: block;
		position: relative;
		text-align: center;
		margin: 20px auto 40px; 
		width: 100%;
		button{
			max-width: 390px;
			width: 100%;
			padding: 10px 20px;
			border: none;
			background: #3A76CE;
			box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
			border-radius: 4px;
			font-weight: 600;
			font-size: 18px;
			text-align: center;
			color: #FFFFFF;
			transition: all 0.3s ease-in-out;
			outline: none;
			text-decoration: none;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			margin: 0 auto;
			cursor: pointer;
			label{
				margin: 0px auto;
			}
			&:hover,
			&:focus{
				background-color: #184282;
			}
			&:disabled{
				background-color: #F0F3F9;
				color: #000326;
				box-shadow: none;
				&:hover,
				&:focus{
					background-color: #F0F3F9;
					color: #000326;
					box-shadow: none;
				}
			}

		}		
	}
	.reassurance-payment{
		display: block;
		position: relative;
		text-align: center;
		margin: 10px auto 60px; 
		width: 100%;
		ul{
			padding: 0px;
			margin: 0px;
			list-style: none;
		}
	}
}


#paymentform{
	#acount-data{
		position: relative;
		width: 100%;
		clear: both;
		display: block;
		margin-bottom: 50px;
		.bordered-label{
			position: relative;
			display: block;
			width: auto;
			margin-bottom: 16px;
			label{
				font-weight: 600;
				font-size: 18px;
				line-height: 160%;
				color: #000326;	
				margin: 0px;
				@include breakpoint(md){
					font-size: 16px;
				}
			}
		}
		#customer{
			border: none;
		    border-radius: 6px;
		    border: 2px solid #bfbfbf;		    
		    padding: 25px 15px 10px 10px;
		    background-color: #fff;
		    display: block;
		    position: relative;
		    @include breakpoint(xs){
				padding: 25px 5px 10px 5px;
			}
		    .text-muted{
		    	text-align: right;
		    	@include breakpoint(md){
					font-size: 11px;
				}
		    }
		    .form-group{
		    	position: relative;
			    margin-bottom: 26px;
			    display: flex;
			    width: 100%;
			    padding: 0px 15px 0px;
			    border: 1px solid #bfbfbf;
			    border-radius: 6px;
			    position: relative;
			    align-items: center;
			    align-content: center;
			    cursor: pointer;
			    transition: all 0.4s ease;
			    @include breakpoint(xs){
					padding: 0px 7px 0px;
				}
			    &:focus,
			    &:hover{
			    	border-color: #bfbfbf;
			    	outline: none;
			    }
			    &.invalid_line{
			    	border-color: #ca2a2a;
			    }
		    	label{
				    font-family: "Nunito", sans-serif;
				    font-size: 14px;
				    color: #000326;
				    width: 138px;
				    white-space: nowrap;
				    overflow: hidden;
				    margin: 0px;
				    margin-right: 12px;
				    position: relative;
				    min-width: 120px;
		    	}
		    	.field-error{
		    		color: #ca2a2a;
				    display: none;
				    font-size: 13px;
				    line-height: 1.4;
				    margin: 0;
				    padding: 0;
				    position: absolute;
				    bottom: -22px;
				    left: 3px;
		    	}
		    	.form-control{
		    		outline: none;
		    		-webkit-font-smoothing: auto;
				    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
				    appearance: textfield;
				    color: rgb(0, 0, 0);
				    direction: ltr;
				    font-family: Nunito, sans-serif;
				    font-size: 16px;
				    font-stretch: 100%;
				    font-style: normal;
				    font-variant-caps: normal;
				    font-variant-east-asian: normal;
				    font-variant-ligatures: normal;
				    font-variant-numeric: normal;
				    font-weight: 400;
				    letter-spacing: normal;
				    line-height: 24px;
				    opacity: 1;
				    text-shadow: none;
				    margin: 0px;
				    outline: rgb(0, 0, 0) none 0px;
				    padding: 1px 2px;
				    transition: all 0s ease 0s;
				    border: none;
				    box-shadow: none;
				    &::-webkit-input-placeholder {
					    color: #8492A5;
					}
					&::-moz-placeholder {
					    color: #8492A5;
					}
					&:-ms-input-placeholder {
					    color: #8492A5;
					}
					&:-moz-placeholder {
					    color: #8492A5;
					}
				    &:focus,
				    &:hover{
				    	border-color: none;
				    	outline: none;
				    }
				    &.invalid_line{
				    	border-color: none;
				    }
		    	}
		    }
		}

	}
	#user-data{
		position: relative;
		width: 100%;
		clear: both;
		display: block;
		margin-bottom: 50px;
		.bordered-label{
			position: absolute;
			left: 5px;
			top: 9px;
			display: flex;
			width: auto;
			label{
				font-weight: 600;
				font-size: 18px;
				line-height: 160%;
				color: #000326;	
				margin: 0px;
				@include breakpoint(md){
					font-size: 16px;
				}
			}
		}
		#dropin-container{
			width: 100%;
			position: relative;
			div[data-braintree-id=choose-a-way-to-pay]{
				margin-top: -10px;
				font-family: 'Nunito', sans-serif;
				font-weight: 600;
			    font-size: 18px;
			    line-height: 160%;
			    color: #000326;
			}
			div[data-braintree-id=upper-container]{
				border-radius: 6px;
				&:before{
					border: 2px solid #bfbfbf;
					border-radius: 6px;
					background-color: #fff
				}
			}
			div[data-braintree-id=paypal]{
				border: 2px solid #bfbfbf;
				div[data-braintree-id=paypal-sheet-header]{
					border-bottom: 2px solid #bfbfbf;
					> div{
						&:first-child{
							> div{
								font-family: 'Nunito', sans-serif;
								font-size: 14px;
								color: #000326;
							}
						}							
					}
				}	
				> div {
					&:last-child{
						padding-top: 25px;
					}
				}
			}
			div[data-braintree-id=toggle]{
				background-color: #fff;
				border-radius: 6px;
				padding: 12px;
				outline: none;
				>span{
					font-family: 'Nunito', sans-serif;
					font-size: 14px;
					color: #000326;
					text-decoration: none;
					padding: 5px 15px 6px;
					border: 2px solid #bfbfbf;
					border-radius: 6px;
					line-height: 1;
				}
			}
			div[data-braintree-id=options]{
				div[data-braintree-id=payment-options-container]{
					> div{
						border-width: 0 2px 2px 2px;
						border-color: #bfbfbf;
						background-color: #fff;
						&:first-child{
							border-radius: 6px 6px 0 0;
							border-top-width: 2px;
						}
						&:last-child{
							border-radius: 0 0 6px 6px;
							border-bottom-width: 2px;
						}
					}

				}
			}
			div[data-braintree-id=card]{
				border: 2px solid #bfbfbf;
				div[data-braintree-id=card-sheet-header]{
					border-bottom: 2px solid #bfbfbf;
					> div{
						&:first-child{
							> div{
								font-family: 'Nunito', sans-serif;
								font-size: 14px;
								color: #000326;
							}
						}							
					}
				}	
				> div {
					&:last-child{
						padding-top: 25px;
						@include media-breakpoint-down(xs) { 	
							padding: 25px 5px 10px 5px
						}
					}
				}
				div[data-braintree-id=number-field-group]{
					position: relative;
					padding-bottom: 16px;
					margin-bottom: 10px;
					@include media-breakpoint-down(xs) { 	
						padding-left: 0px;
					}
					> label{
					    display: flex;
					    width: 100%;
					    padding: 0px 15px 0px;
					    border: 1px solid #bfbfbf;
					    border-radius: 6px;
					    position: relative;
					    align-items: center;
					    align-content: center;
					    cursor: pointer;
					    transition: all 0.4s ease;
					    @include media-breakpoint-down(xs) { 	
							padding: 0px 7px 0px;
						}
					    > div{
							&:first-child{								
								font-family: 'Nunito', sans-serif;
								font-size: 14px;
								color: #000326;	
								width: 138px;
								white-space: nowrap;
								overflow: hidden;
								margin-right: 12px;	
								position: relative;
								@include media-breakpoint-down(xs) { 	
									width: 150px;
									margin-right: 4px;
									font-size: 14pxer;
								}
								@media screen and (max-width: 349px){
									font-size: 11px;
								}
								&:after{
									content: ":";
									padding-left: 2px;
								}						
							}	
							&:last-child{								
								display: flex;
								width: 100%;
								> div{
									&:first-child{
										width: 100%;
										border:none;
										margin: 0px;
										@include media-breakpoint-down(xs) { 	
											padding: 0px 4px 0px;
										}
										input{
											&::-webkit-input-placeholder {
											    color: #8492A5;
											}
											&::-moz-placeholder {
											    color: #8492A5;
											}
											&:-ms-input-placeholder {
											    color: #8492A5;
											}
											&:-moz-placeholder {
											    color: #8492A5;
											}
										}
									}
								}							
							}						
						}
					}
					div[data-braintree-id=number-field-error]{
						position: absolute;
						bottom: -5px;
						left: 20px;
						font-family: 'Nunito', sans-serif;
						margin: 0px;
					}

				}
				div[data-braintree-id=expiration-date-field-group]{
					flex-basis: 100%;
					position: relative;
					padding-bottom: 16px;
					margin-bottom: 10px;
					@include media-breakpoint-down(xs) { 	
						padding-left: 0px;
					}
					> label{
					    display: flex;
					    width: 100%;
					    padding: 0px 15px 0px;
					    border: 1px solid #bfbfbf;
					    border-radius: 6px;
					    position: relative;
					    align-items: center;
					    align-content: center;
					    cursor: pointer;
					    transition: all 0.4s ease;
					    @include media-breakpoint-down(xs) { 	
							padding: 0px 7px 0px;
						}
					    > div{
							&:first-child{								
								font-family: 'Nunito', sans-serif;
								font-size: 14px;
								color: #000326;	
								width: 138px;
								white-space: nowrap;
								overflow: hidden;
								margin-right: 12px;	
								position: relative;
								@include media-breakpoint-down(xs) { 
									width: 150px;
									margin-right: 4px;	
									font-size: 14pxer;
								}
								@media screen and (max-width: 349px){
									font-size: 11px;
								}
								&:after{
									content: ":";
									position: absolute;
									right: 0px;
								}						
							}	
							&:last-child{								
								display: flex;
								width: 100%;
								> div{
									&:first-child{
										width: 100%;
										border:none;
										margin: 0px;
										@include media-breakpoint-down(xs) { 	
											padding: 0px 4px 0px;
										}
										input{
											&::-webkit-input-placeholder {
											    color: #8492A5;
											}
											&::-moz-placeholder {
											    color: #8492A5;
											}
											&:-ms-input-placeholder {
											    color: #8492A5;
											}
											&:-moz-placeholder {
											    color: #8492A5;
											}
										}
									}
								}							
							}						
						}
					}
					div[data-braintree-id=expiration-date-field-error]{
						position: absolute;
						bottom: -5px;
						left: 20px;
						font-family: 'Nunito', sans-serif;
						margin: 0px;
					}
				}
				div[data-braintree-id=cvv-field-group]{
					flex-basis: 100%;
					position: relative;
					padding-bottom: 16px;
					margin-bottom: 10px;
					@include media-breakpoint-down(xs) { 	
						padding-left: 0px;
					}
					> label{
					    display: flex;
					    width: 100%;
					    padding: 0px 15px 0px;
					    border: 1px solid #bfbfbf;
					    border-radius: 6px;
					    position: relative;
					    align-items: center;
					    align-content: center;
					    cursor: pointer;
					    transition: all 0.4s ease;
					    @include media-breakpoint-down(xs) { 	
							padding: 0px 7px 0px;
						}
					    > div{
							&:first-child{								
								font-family: 'Nunito', sans-serif;
								font-size: 14px;
								color: #000326;	
								width: 138px;
								white-space: nowrap;
								overflow: hidden;
								margin-right: 12px;	
								position: relative;								
								span{
									letter-spacing: 0.04rem;
								}
								@include media-breakpoint-down(xs) { 	
									font-size: 14pxer;
									margin-right: 4px;
									span{
										letter-spacing: 0;
										font-size: 14pxer;
									}
								}
								@media screen and (max-width: 349px){
									font-size: 11px;
									span{
										letter-spacing: 0;
										font-size: 11px;
									}
								}
								&:after{
									content: ":";
									position: absolute;
									right: 0px;
								}						
							}	
							&:last-child{								
								display: flex;
								width: 100%;
								> div{
									&:first-child{
										width: 100%;
										border:none;
										margin: 0px;
										@include media-breakpoint-down(xs) { 	
											padding: 0px 4px 0px;
										}
										input{
											&::-webkit-input-placeholder {
											    color: #8492A5;
											}
											&::-moz-placeholder {
											    color: #8492A5;
											}
											&:-ms-input-placeholder {
											    color: #8492A5;
											}
											&:-moz-placeholder {
											    color: #8492A5;
											}
										}
									}
								}							
							}						
						}
					}
					div[data-braintree-id=cvv-field-error]{
						position: absolute;
						bottom: -5px;
						left: 20px;
						font-family: 'Nunito', sans-serif;
						margin: 0px;
					}
				}
			}
			
		}

	}
}


#payments-holder,
#products-holder{
	position: relative;
	width: 100%;
	padding-top: 90px;
	padding-bottom: 20px;	
	&.bigger-padding-bottom{
		padding-top: 20px;
		padding-bottom: 90px;	
	}
	@include breakpoint(md){
		padding-top: 40px;
	}
	.test-products-heading{
		width: 100%;
		padding-bottom: 40px;
		@include breakpoint(xs){
			padding-bottom: 10px;
		}
		h2{
			font-weight: bold;
			font-size: 44px;
			color: #000326;
			margin: 0px;
			strong{
				font-weight: bold;
				color: #000326;
			}
			@include breakpoint(md){
				font-size: 32px;
			}
			@include breakpoint(xs){
				font-size: 18px;
				margin-bottom: 15px;
			}
		}
		h4{
			font-weight: bold;
			font-size: 24px;
			color: #000326;
			margin: 0px;
			strong{
				font-weight: bold;
				color: #000326;
			}
			@include breakpoint(md){
				font-size: 20px;
			}
			@include breakpoint(xs){
				font-size: 18px;
				margin-bottom: 15px;
			}
		}
	}

}


.product-boxes{	
    direction: ltr;
    display: block;
    position: relative;
	@include breakpoint(min-md){
		width: 100%;
		display:flex;
	    flex-direction:row;
		justify-content: space-between;
		align-items: stretch;
		align-content: center;
	}
	&.centered-flex{
		justify-content: center;
	}
	.product-box-holder{
		@include breakpoint(md){
			max-width: 350px;
			margin: 15px auto 20px;		
			width: 100%;
			background: #FFFFFF;
			border: 1px solid #C9D3DE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			padding: 30px 24px;
			flex-direction: column;
			z-index: 2;
			position: relative;
			flex-basis:100%;

		}
		@include breakpoint(min-md){
			max-width: 350px;
			margin: 0;		
			width: 100%;
			background: #FFFFFF;
			border: 1px solid #C9D3DE;
			box-sizing: border-box;
			box-shadow: 0px 4px 5px rgba(59, 72, 87, 0.06);
			border-radius: 4px;
			padding: 30px 24px;
			flex-direction: column;
			z-index: 2;
			position: relative;
			flex-basis:100%;
		}
		.corner-label{
			position: absolute;
			right: 14px;
			top: -10px;
			padding: 8px 12px;
			display: block;
			text-align: center;
			color: #fff;
			border-radius: 4px;
			background-color: #FFB527;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			&.best-value{
				background-color: #16BD81;
			}
			&.current-plan{
				background-color: #3A76CE;
			}
			label{
				margin: 0px;
				font-weight: 600;
				font-size: 12px;
				line-height: 1;
				color: #FFFFFF;
			}
		}
		.product-title{
			display: flex;
			justify-content: center;
			align-items: center;
			h4{
				font-weight: bold;
				font-size: 16px;
				text-align: center;
				color: #000326;
			}
		}
		.product-price-row{			
			display: flex;
			width: 100%;
			padding: 0 10px;
			position: relative;
			align-items: center;
			label{
				display: flex;
				justify-content: center;
				padding: 10px;
				align-content: center;
				align-items: flex-start;
				position: relative;
				width: 100%;
				mark{
					font-weight: 600;
					font-size: 52px;
					line-height: 1;
					text-align: center;
					color: #000326;
					display: flex;
					position: relative;
				}
				span.currency-sign{
					font-weight: 700;
					font-size: 16px;
					line-height: 1;
					text-align: center;
					color: #000326;
					margin-top: 7px;
					margin-right: 4px;
				}
				&.striked-price{
					color: #EC6161;
					mark{
						font-size: 32px;
						color: #EC6161;
						font-weight: 500;
						&:after{
							content:"";
							height: 3px;
						    width: 155%;
						    -ms-transform: rotate(-22deg);
							transform: rotate(-22deg);
						    position: absolute;
						    background: #EC6161;
						    z-index: 2;
						    left: -30%;
						    bottom: 12px;
						    border-radius: 4px;
						}
					}
					span.currency-sign{
						font-size: 13px;
						color: #EC6161;
						margin-top: 4px;
						margin-right: 2px;
					}					
				}
				&.discounted-price{
					color: #3A76CE;
					mark{
						font-size: 44px;
						color: #3A76CE;
						&:after{
							content:"";
							height: 3px;
						    width: 110%;
						    position: absolute;
						    background: #3A76CE;
						    z-index: 2;
						    left: -12%;
						    bottom: -2px;
						    border-radius: 4px;
						}
					}
					span.currency-sign{
						font-size: 16px;
						color: #3A76CE;
						margin-top: 4px;
						margin-right: 4px;
					}					
				}
			}
		}
		.product-description-row{
			display: flex;
			width: 100%;
			padding: 0 10px 32px;
			position: relative;
			justify-content: center;
			p{
				font-weight: 600;
				font-size: 14px;
				line-height: 100%;
				text-align: center;
				color: #000326;
				margin: 0 auto;
			}
		}
		.product-benefits-row{
			display: flex;
			width: 100%;
			padding: 24px 0;
			position: relative;
			border-top: 1px solid #E0E6ED;
			border-bottom: 1px solid #E0E6ED;			
			label{
				font-weight: 600;
				font-size: 14px;
				color: #8492A5;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				margin: 0 auto;
				&.negative{
					color: #EC6161;
				}
				&.positive{
					color: #16BD81;
				}
				span{
					margin-right: 8px;
					line-height: 1;
				}
			}
		}
		.products-rows{
			display: flex;
			width: 100%;
			padding: 32px 0;
			position: relative;
			list-style: none;
			flex-direction: column;
			margin: auto;
			li{
				display: flex;
				width: 100%;
				position: relative;
				align-items: center;
				align-content: center;
				padding-bottom: 12px;
				margin: 2px 0px;
				z-index: 1;
				&.product-title-row{
					margin-bottom: 10px;
				}
				&.discount-notification{
					min-height: 36px;
				}
				.product-offer{
					display: flex;
					width: 100%;
					align-items: center;
					align-content: center;
					justify-content: space-between;
					margin: 0px;
					z-index: 1;
					.p-title{
						font-weight: 600;
						font-size: 16px;
						color: #8492A5;
						margin: 0px;
						display: flex;
						align-content: center;
						align-items: center;
						&.with-padding{
							padding-right: 16px;
						}
						span{
							margin-right: 6px;
						    min-width: 24px;
						    justify-content: center;
						    display: flex;
						}
						label{
							font-weight: 600;
							font-size: 14px;
							color: #8492A5;
							display: flex;
							justify-content: center;
							align-items: center;
							align-content: center;
							margin: 0 0;
							&.negative{
								color: #EC6161;
							}
							&.positive{
								color: #16BD81;
							}
							span{
								margin-right: 8px;
								line-height: 1;
							}
						}
					}
				}
				.checkmark{
					position: relative;
					width: 22px;
					margin-right: 7px;
					height: 22px;
					justify-content: center;
					display: flex;
					align-content: center;
					align-items: center;
					position: relative;
					margin-left: 3px;
					top: auto;
					bottom: auto;
					right: auto;
					left: auto;
				}

			}
		}
		.buy-button{
			display: flex;
			width: 100%;
			padding: 0;
			position: relative;
			align-self:flex-end;
			button{
				max-width: 390px;
				width: 100%;
				padding: 10px 20px;
				border: none;
				background: #3A76CE;
				box-shadow: 0px 4px 6px rgba(48, 77, 119, 0.2);
				border-radius: 4px;
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #FFFFFF;
				transition: all 0.3s ease-in-out;
				outline: none;
				text-decoration: none;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				margin: 0 auto;
				label{
					margin: 0px auto;
				}
				&:hover,
				&:focus{
					background-color: #184282;
				}
				&:disabled{
					background-color: #F0F3F9;
					color: #000326;
					box-shadow: none;
					&:hover,
					&:focus{
						background-color: #F0F3F9;
						color: #000326;
						box-shadow: none;
					}
				}

			}
		}

	}
}